import {
    POSTJOB_GET,
    COMPLEXITY_GET,
	EXPECTEDDUARATION_GET,
	PAYMENTTYPE_GET,
	SKILLS_GET,
	JOBDETAIL_GET,
	POSTJOB_GET_ALL,
	POSTJOB_GET_ALL_COUNT,
	POSTJOB_GET_SKILLS,
} from '../actions/types';

const initialState = {
	postJobData: null,
	complexityLevel: {
		_id: '',
		complexity: ''
	},
	expectedDurationVal: {
		_id: '',
		duration_text: ''
	},
	paymentTypeVal: {
		_id: '',
		type_name: ''
	},
	skills: {
		_id: '',
		skill_name: ''
	},
	deleteJobData: {
		id: '',
		headline: ''
	},
	jobDetailData: {
		_id: '',
		hire_manager_id: '',
		expected_duration_id: '',
		complexity_id: '',
		description: '',
		main_skill_id: '',
		payment_type_id: '',
		payment_amount: '',
		headline: '',
		job_post_time: '',
		location: ''
	},
	applyforjob: {
		id: ''
	},
	postJobDataAll: null,
	jobCount: 0
}

export default function postJob(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
	
		case POSTJOB_GET:
			//console.log('Type of action.payload:', typeof payload);
			return {
				...state, isAuthenticated: true, loading: false, postJobData: payload
			}
		case POSTJOB_GET_ALL:
			//console.log('Type of action.payload:', typeof payload);
			return {
				...state, isAuthenticated: true, loading: false, postJobDataAll: payload
			}
		case POSTJOB_GET_ALL_COUNT:
			return {
				...state, isAuthenticated: true, loading: false, jobCount: payload
			}	
			case POSTJOB_GET_SKILLS:
				//debugger
			return {
				...state, isAuthenticated: true, loading: false, skillss: payload
			}	
		case COMPLEXITY_GET:
			return {
				...state, isAuthenticated: true, loading: false, complexityLevel: payload
			}
		case EXPECTEDDUARATION_GET:
			return {
				...state, isAuthenticated: true, loading: false, expectedDurationVal: payload
			}
		case PAYMENTTYPE_GET:
			return {
				...state, isAuthenticated: true, loading: false, paymentTypeVal: payload
			}
		case SKILLS_GET:
			return {
				...state, isAuthenticated: true, loading: false, skills: payload
			}
		case JOBDETAIL_GET:
			return {
				...state, isAuthenticated: true, loading: false, jobDetailData: payload
			}
		default:
			return state;
	}
}