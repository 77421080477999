import {
  GET_WITHDRAWAL_REQUESTS,
  GET_WITHDRAWAL,
  GET_WITHDRAWAL_SUMMARY,
} from "../actions/types";

const initialState = {
  withdrawals: null,
  withdrawal: null,
  withdrawalSummary: null,
};

export default function withdrawal(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_WITHDRAWAL_REQUESTS:
      return {
        ...state,
        withdrawals: payload,
      };
    case GET_WITHDRAWAL:
      return {
        ...state,
        withdrawal: payload,
      };
    case GET_WITHDRAWAL_SUMMARY:
      return {
        ...state,
        withdrawalSummary: payload,
      };
    default:
      return state;
  }
}
