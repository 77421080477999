import {
    APPROVED_TAPS_CHARGE,
} from '../actions/types';

const initialState = {
    charges: [],
    loading: true,
    error: null
};

export default function taps(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case APPROVED_TAPS_CHARGE:   
            return {
                ...state,
                charges: action.payload,
                loading: false
            };
      
        default:
            return state;
    }
}
