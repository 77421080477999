import axios from 'axios';
import { setAlert } from './alert';
import {
	HIREMANAGER_GET,
	SKILLS_GET,
} from './types';
import setAuthToken from '../utils/setAuthToken';
import { loadUser } from './auth';

// Skills Get
export const skillsGet = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
	
	try {
		const res = await axios.get('/api/skill');

		if (res.data == null)
			res.data = [];

		dispatch({
			type: SKILLS_GET,
			payload: res.data
		});

	} catch (err) {
		
		const errors = err?.response?.data;

		if (errors) {
			dispatch(setAlert(errors?.msg, 'danger'))
		}
	}
}


// hireManager Get
export const hireManagerGet = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
	
	try {
		const res = await axios.get('/api/hireManager/me'); //setitem or get item
		localStorage.setItem('hireManager', JSON.stringify(res));

		if (res.data == null)
			res.data = [];
		dispatch({
			type: HIREMANAGER_GET,
			payload: res.data
		});

	} catch (err) {
		
		const errors = err.response.data;

		// if (errors) {
		// 	dispatch(setAlert(errors.msg, 'danger'))
		// }
	}
}

// Add hireManager Value
export const addhireManager = () => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const body = JSON.stringify({});

	try {
		debugger;
		const res = await axios.post('/api/hireManager', body, config);
		//console.log(res.data);
		if(res.data){
			dispatch(hireManagerGet());
		}
		return res;
		
	} catch (err) {

		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}

}

export const verifyClient = (email) => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const body = JSON.stringify({
		email
	});
	
	try {
		const res = await axios.post('/api/hireManager/verify', body, config);

		if (res.data == null)
			res.data = [];

		dispatch(setAlert(res.data.msg, 'success'));
		dispatch(hireManagerGet());
		dispatch(loadUser());

	} catch (err) {
		const errorMessage = err?.response?.data?.msg || err.message;
		dispatch(setAlert(errorMessage, 'danger'));
	}
}

