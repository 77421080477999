export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const AUTH_START_REQUEST = 'AUTH_START_REQUEST';
export const AUTH_END_REQUEST = 'AUTH_END_REQUEST';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const AUTH_ERROR = 'AUTH_ERROR';

export const USER_LOADED = 'USER_LOADED';

export const CERTIFICATE_GET = 'CERTIFICATE_GET';
export const EXPERTISE_GET = 'EXPERTISE_GET';

export const FREELANCER_STATUS_GET = 'FREELANCER_STATUS_GET';
export const FREELANCER_IS_COMPLETED_GET = 'FREELANCER_IS_COMPLETED_GET';
export const FREELANCER_LIST_GET = 'FREELANCER_LIST_GET';
export const FREELANCER_GET = 'FREELANCER_GET';
export const FREELANCER_GET_BY_ID = 'FREELANCER_GET_BY_ID';

export const EXPERTLEVEL_GET = 'EXPERTLEVEL_GET';
export const EDUCATION_GET = 'EDUCATION_GET';
export const EMPLOYMENT_GET = 'EMPLOYMENT_GET';
export const REVIEWS_GET = 'REVIEWS_GET';
export const LANGUAGE_GET = 'LANGUAGE_GET';
export const HOURLYRATE_GET = 'HOURLYRATE_GET';
export const FREELANCERTITLEOVERVIEW_GET = 'FREELANCERTITLEOVERVIEW_GET';
export const PROFILEANDPHOTO_GET = 'PROFILEANDPHOTO_GET';
export const FREELANCERLOCATION_GET = 'FREELANCERLOCATION_GET';
export const PHONENUMBER_GET = 'PHONENUMBER_GET';
export const HIREMANAGER_GET = 'HIREMANAGER_GET';
export const SKILLS_GET = 'SKILLS_GET';

export const POSTJOB_GET = 'POSTJOB_GET';
export const POSTJOB_GET_ALL = 'POSTJOB_GET_ALL';
export const POSTJOB_GET_ALL_COUNT = 'POSTJOB_GET_ALL_COUNT';
export const POSTJOB_GET_SKILLS = 'POSTJOB_GET_SKILLS';
export const COMPLEXITY_GET = 'COMPLEXITY_GET';
export const EXPECTEDDUARATION_GET = 'EXPECTEDDUARATION_GET';
export const PAYMENTTYPE_GET = 'PAYMENTTYPE_GET';
export const JOBDETAIL_GET = 'JOBDETAIL_GET';
export const ATTACHMENT_GET = 'ATTACHMENT_GET';

export const CONTRACT_GET = 'CONTRACT_GET';
export const CONTRACT_GET_BY_ID = 'CONTRACT_GET_BY_ID';
export const CONTRACT_GET_SUMMARY = 'CONTRACT_GET_SUMMARY';

export const MESSAGE_GET = 'MESSAGE_GET';
export const MESSAGE_GET_SUMMARY = 'MESSAGE_GET_SUMMARY';
export const PROPOSAL_GET = 'PROPOSAL_GET';

export const PROPOSALCHECK_GET = 'PROPOSALCHECK_GET';
export const CLIENTPROPOSALS_GET = 'CLIENTPROPOSALS_GET';
export const CLIENTPROPOSALSUSERNAME_GET = 'CLIENTPROPOSALSUSERNAME_GET';
export const PROPOSALSTATUSCATALOG_GET = 'PROPOSALSTATUSCATALOG_GET';

export const EMAILSENT = 'EMAILSENT';
export const PENDING_JOBS_GET_SUCCESS="PENDING_JOBS_GET_SUCCESS";

export const OTP_ID_INSERT = 'OTP_ID_INSERT';

export const TOTAL_USERS = 'TOTAL_USERS';
export const BY_DATE_USERS = 'BY_DATE_USERS';
export const BY_COUNTRY_USERS = 'BY_COUNTRY_USERS'; 
export const TOTAL_USERS_CHARTS = 'TOTAL_USERS_CHARTS';
export const BY_COUNTRY_USERS_CHARTS = 'BY_COUNTRY_USERS_CHARTS';

export const SETCONTRACTINFORMATION = 'SETCONTRACTINFORMATION';

export const INSERTWALLET = 'INSERTWALLET';
export const GETTRANSACTION = 'GETTRANSACTION';
export const SETTRANSACTION = 'SETTRANSACTION';
export const GET_TRANSACTION_HISTORY = 'GET_TRANSACTION_HISTORY';
export const RELEASE_PAYMENT = 'RELEASE_PAYMENT';

export const PAYPAL_AMOUNT = 'PAYPAL_AMOUNT';

export const GIVE_FEEDBACK = 'GIVE_FEEDBACK';
export const USER_STATS = 'USER_STATS';

export const GET_BANKS = 'GET_BANKS';
export const GET_BANK = 'GET_BANK';

export const GET_WITHDRAWAL_REQUESTS = 'GET_WITHDRAWAL_REQUESTS';
export const GET_WITHDRAWAL = 'GET_WITHDRAWAL';
export const GET_WITHDRAWAL_SUMMARY = 'GET_WITHDRAWAL_SUMMARY';

export const PROFILE_COMPLETE_PERCENTAGE = 'PROFILE_COMPLETE_PERCENTAGE';


export const ADMIN_GET_USERS = "ADMIN_GET_USERS";
export const ADMIN_GET_USERS_COUNT = "ADMIN_GET_USERS_COUNT";
export const GET_ADMIN_MESSAGES = "GET_ADMIN_MESSAGES";
export const GOOGLE_ANALYTICS_REPORT = "GOOGLE_ANALYTICS_REPORT";

export const DISPUTES_GET_SUCCESS = 'DISPUTES_GET_SUCCESS';
export const DISPUTES_GET_FAIL = 'DISPUTES_GET_FAIL';


export const AI_EXPERT_RECOMENDATIONS = 'AI_EXPERT_RECOMENDATIONS';
export const APPROVED_TAPS_CHARGE = 'APPROVED_TAPS_CHARGE';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_CHAT = 'GET_CHAT';
export const GET_INDIVIDUAL = 'GET_INDIVIDUAL';
export const CLEAR_RECOMMENDATIONS = "CLEAR_RECOMMENDATIONS";