import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from 'react-icons-kit';
import { facebookSquare } from 'react-icons-kit/fa/facebookSquare';
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare';
import { mapMarker } from 'react-icons-kit/fa/mapMarker';
import { userO } from 'react-icons-kit/fa/userO';
import { clockO } from 'react-icons-kit/fa/clockO';
import { paperPlane } from 'react-icons-kit/fa/paperPlane';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactStars from "react-rating-stars-component";
import { Helmet } from "react-helmet";

import { getUserStats } from "../../actions/proposalAndContract";

import Skeleton from "./../../components/skeleton";
import { Detector } from "react-detect-offline";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';

const PublicProfile = ({ freelancerProfile: { expertise, expertLevel, employment, certification, languageVal, education, hourlyRate, Location, TitleAndOverview, profileAndPhoto, phoneNumber, freelancerprofile, reviews }, ContractSummary, getUserStats, userStats }) => {

    //const dispatch = useDispatch();

    //console.log(reviews);
    // const { user_id } = useParams();
    const path = window.location.pathname;

    // Extract the number after "profile" using a regular expression
    const match = path.match(/\/profile\/([\w-]+)/);
    const user_id = match ? match[1] : null;
    const [profileData, setProfileData] = useState({
        user: {
            firstName: '',
            lastName: '',
        },
        skills: null,
        employment: [],
        education: [],
        hourlyRate: '',
        Location: '',
        TitleAndOverview: '',
        profileAndPhoto: '',
        phoneNumber: '',
        reviews: [],
    });

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                if (user_id) {
                    const response = await axios.get(`https://cybertocyber.com/api/profile/public/${user_id}`);
                    const profile = response.data;

                    setProfileData({
                        user: {
                            firstName: profile.first_name || '',
                            lastName: profile.last_name || '',
                        },
                        skills: profile.skills || null,
                        employment: profile.employment || [],
                        education: profile.education || [],
                        hourlyRate: profile.hourlyRate || '',
                        Location: profile.Location || '',
                        TitleAndOverview: profile.TitleAndOverview || '',
                        profileAndPhoto: profile.profilePhoto || '',
                        phoneNumber: profile.phoneNumber || '',
                        reviews: profile.reviews || [],
                        certification: profile.certifications || "",
                        expertise: profile.expertise || "",
                        language: profile.language || '',
                        expertLevel: profile.expertLevel || '',
                        userStats : profile.reviewStats || '',
                        ContractSummary : profile.contract || ''
                    });
                    debugger

                    localStorage.setItem('firstName', JSON.stringify(profile.first_name));
                    localStorage.setItem('lastName', JSON.stringify(profile.last_name));
                    localStorage.setItem('skills', JSON.stringify(profile.skills));
                    localStorage.setItem('employment', JSON.stringify(profile.employment));
                    localStorage.setItem('education', JSON.stringify(profile.education));
                    localStorage.setItem('hourlyRate', JSON.stringify(profile.hourlyRate?.hourly_rate));
                    localStorage.setItem('location', JSON.stringify(profile.Location));
                    localStorage.setItem('titleAndOverview', JSON.stringify(profile.TitleAndOverview));
                    localStorage.setItem('profilePhoto', JSON.stringify(profile.profilePhoto));
                    localStorage.setItem('phoneNumber', JSON.stringify(profile.phoneNumber));
                    localStorage.setItem('user', JSON.stringify({ firstName: profile.first_name, lastName: profile.last_name }));
                    localStorage.setItem('reviews', JSON.stringify(profile.reviews));
                } else {
                    const storedProfileData = {
                        user: {
                            firstName: JSON.parse(localStorage.getItem('firstName')) || '',
                            lastName: JSON.parse(localStorage.getItem('lastName')) || '',
                        },
                        skills: JSON.parse(localStorage.getItem('skills')) || null,
                        employment: JSON.parse(localStorage.getItem('employment')) || [],
                        education: JSON.parse(localStorage.getItem('education')) || [],
                        hourlyRate: JSON.parse(localStorage.getItem('hourlyRate')) || '',
                        Location: JSON.parse(localStorage.getItem('location')) || '',
                        TitleAndOverview: JSON.parse(localStorage.getItem('titleAndOverview')) || '',
                        profileAndPhoto: JSON.parse(localStorage.getItem('profilePhoto')) || '',
                        phoneNumber: JSON.parse(localStorage.getItem('phoneNumber')) || '',
                        reviews: JSON.parse(localStorage.getItem('reviews')) || [],
                    };

                    setProfileData(storedProfileData);
                }
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchProfileData();
    }, [user_id]);



    const [ratingText, setRatingText] = useState({
        ratingTextData: "",
    });


    //getFreelancer();
    //console.log(freelancerprofile);




    const { ratingTextData } = ratingText;

    const [show, setShow] = useState(false);

    const [showGetAQuote, setShowGetAQuote] = useState(false);

    const [showReview, setShowReview] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseGetAQuote = () => setShowGetAQuote(false);
    const handleShowGetAQuote = () => setShowGetAQuote(true);

    const handleCloseReview = () => {
        setShowReview(false);
        setFeedbackText('');
    };
    const handleShowReview = () => setShowReview(true);

    const ratingChanged = (newRating) => {
        setRatingText(newRating);
    };


    // Define state variables to store the feedback text and rating values
    const [feedbackText, setFeedbackText] = useState('');
    const [satisfactionRating, setSatisfactionRating] = useState(0);
    const [projectDeliveryRating, setProjectDeliveryRating] = useState(0);


    const handleProjectDeliveryRatingChange = (newRating) => {
        setProjectDeliveryRating(newRating);
        //console.log(newRating)
    };

    // Update the callback functions to set the state variables with the rating values
    const handleSatisfactionRatingChange = (newRating) => {
        setSatisfactionRating(newRating);
        //console.log(newRating)
        //console.log(feedbackText)
    };

    const [pageLoaded, setPageLoaded] = useState(false);


    useEffect(() => {
        setPageLoaded(true);
    }, []);

    // useEffect(() => {
    //     getUserStats();
    // }, [pageLoaded, getUserStats]);



    const calculatePercentage = (total, max) => {
        return Math.round(total / max) * 100;
    }

    return (
        <section className="main-page page-dashboard">
            <Helmet>
                <title>Profile</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 left">
                        <div className="profile_info bg-white rounded shadow-sm">
                            <div className="seller-card">
                                <div className="clearfix">
                                    <Detector
                                        render={({ online }) => (
                                            <div className="user-online-indicator is-online"
                                                style={{ color: online ? "#008000" : "#e31818" }}>
                                                <i className="fa fa-circle"></i> {online ? "online" : "offline"}
                                            </div>
                                        )}
                                    />
                                    {/* <Link to="#" className="ambassadors-badge"
                                        style={{ backgroundColor: user?.type ? "#40ADDB" : "transparent" }}>
                                        {!user?.type ? (
                                            <Skeleton width={100} height={25} />
                                        ) : user.type === "Freelancer" ? (
                                            "Expert"
                                        ) : (
                                            user.type
                                        )}
                                    </Link> */}
                                </div>
                                <div className="user-profile-info">
                                    <div>
                                        <div className="user-profile-image">
                                            <label className="user-pict rounded-circle">
                                                {/*<img
                                                src="assets/images/BilalAhmedProfile.jpg"
                                                className="img-fluid user-pict-img" alt="Cyber2Cyber" />*/}
                                                <Link to="#" className="user-badge user-badge-round user-badge-round-med locale-en-us top-rated-seller">
                                                    {/* <h1>{profileData?.first_name}</h1> */}
                                                    {
                                                        profileData.profileAndPhoto?.photo_link ?
                                                            <img src={profileData.profileAndPhoto.photo_link} alt="Profile picture" />
                                                            :
                                                            <h3 className="text-uppercase profile-avatar">{profileData.user.firstName.slice(0, 1)}{profileData.user.lastName.slice(0, 1)}</h3>
                                                    }
                                                </Link>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="user-profile-label">
                                        <div className="username-line">
                                            <Link to="#" className="seller-link">{profileData.user.firstName} {profileData.user.lastName}</Link>
                                            {freelancerprofile.verification == true ? <img src="assets\images\checkmark.png" className="img-fluid user-pict-img" alt="Cyber2Cyber" title="Cyber to Cyber Verified Expert" style={{ width: "5%", height: "4%", position: "relative", top: "-3px", left: "3px" }} /> : console.log("check false")}
                                        </div>
                                        <div className="oneliner-wrapper">
                                            <small className="oneliner">{profileData.TitleAndOverview?.title}</small>
                                            <div className="ratings-wrapper">
                                                {/*<p className="rating-text"><strong>5.0</strong> (1k+ reviews)</p>*/}
                                                <p className="rating-text"><strong>${profileData.hourlyRate.hourly_rate}</strong>/hr</p>
                                            </div>
                                        </div>
                                    </div>
                                    <section className="pl-4 pr-4">
                                        <div className="border-bottom pb-4">
                                            {/*user.type === 'Freelancer' && (
                                            <>
                                                {userStats && userStats.length > 0 ? (
                                                    <>
                                                        <div className="d-flex align-items-center py-1">
                                                            <span className="stats-title">Delivered on Time</span>
                                                            <div className="font-weight-bold ml-auto">
                                                                <span className="stats-value">
                                                                    {calculatePercentage(userStats[0].satisfaction_rating, userStats[0].count * 5)}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center py-1">
                                                            <span className="stats-title">Order Completion</span>
                                                            <div className="font-weight-bold ml-auto">
                                                                <span className="stats-value">
                                                                    {calculatePercentage(userStats[0].delivery_rating, userStats[0].count * 5)}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <Skeleton width={100} height={18} />
                                                )}
                                            </>       
                                                )*/}
                                            {profileData.userStats ? (
                                                profileData.userStats.satisfaction_rating ? (
                                                    <>
                                                        <div className="d-flex align-items-center py-1">
                                                            <span className="stats-title">Delivered on Time</span>
                                                            <div className="font-weight-bold ml-auto">
                                                                <span className="stats-value">
                                                                    {calculatePercentage(profileData.userStats.satisfaction_rating, profileData.userStats.count * 5)}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center py-1">
                                                            <span className="stats-title">Order Completion</span>
                                                            <div className="font-weight-bold ml-auto">
                                                                <span className="stats-value">
                                                                    {calculatePercentage(profileData.userStats.delivery_rating, userStats.count * 5)}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="d-flex align-items-center py-1">
                                                        <span className="stats-title">Delivered on Time</span>
                                                        <div className="font-weight-bold ml-auto">
                                                            <span className="stats-value">N/A</span>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <Skeleton width={100} height={18} />
                                            )}

                                        </div>
                                        <div className="pt-4 pb-4">
                                            {/*ContractSummary && ContractSummary.length > 0 ? (
                                            <div className="d-flex align-items-center py-1 h6">
                                                <span className="stats-title">Total Earnings</span>
                                                <div className="font-weight-bold ml-auto">
                                                    <span className="stats-value">
                                                        ${ContractSummary[0].total.toLocaleString()}
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <Skeleton width={100} height={18} />
                                        )*/}
                                            {profileData.ContractSummary ? (
                                                profileData.ContractSummary.length > 0 ? (
                                                    <div className="d-flex align-items-center py-1 h6">
                                                        <span className="stats-title">Total Earnings</span>
                                                        <div className="font-weight-bold ml-auto">
                                                            <span className="stats-value">
                                                                ${profileData.ContractSummary[0].total.toLocaleString()}
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-center py-1 h6">
                                                        <span className="stats-title">Total Earnings</span>
                                                        <div className="font-weight-bold ml-auto">
                                                            <span className="stats-value">N/A</span>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <Skeleton width={100} height={18} />
                                            )}
                                        </div>
                                    </section>
                                    <div className=" bg-white my-2 w-100">
                                       Share profile via  Url : <small>{window.location.origin}{window.location.pathname}</small> 
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 right">
                        <div className="seller-profile">
                            <div className="description">
                                <div className="text-right">
                                    {/* <Link to="/personaldetails" className="btn btn-primary">Edit Profile</Link> */}
                                </div>
                                <h3>Description</h3>
                                <ul className="mt-2 pl-0">
                                    <li className="interactive-bullet">{profileData.TitleAndOverview?.professional_overview ?? 'No Record Found'}</li>
                                </ul>
                            </div>
                            <div className="languages">
                                <h3 className="mt-3">Languages</h3>
                                <ul className="mt-2 pl-0">
                                    {profileData.language?.length > 0 ? profileData.language?.map((itemoflanguage, index) => (
                                        <li className="interactive-bullet" key={index}>

                                            <p> {itemoflanguage.language}&nbsp;&nbsp;- <span>{itemoflanguage.proficiency}</span></p>
                                        </li>
                                    )) :
                                        <li className="interactive-bullet">
                                            No Record Found
                                        </li>
                                    }
                                </ul>
                            </div>
                            {/*<div className="linked-accounts">
                            <h3>Linked Accounts</h3>
                            <ul>
                                <li><Icon className="mr-2" icon={facebookSquare} /><span className="text">Facebook</span></li>
                                <li><Icon className="mr-2" icon={linkedinSquare} /><span className="text">LinkedIn</span></li>
                            </ul>
                        </div>*/}
                            <div className="skills">
                                <h3>Main Service You Offer</h3>
                                <ul className="mt-2 pl-0">
                                    <li className="interactive-bullet">

                                        <p>{profileData.expertise?.category ? profileData.expertise?.category : "No Record Found"}</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="skills">
                                <h3>Skills</h3>
                                <ul className="mt-2 pl-0">
                                    <li className="interactive-bullet">{profileData.expertise?.skills ? profileData.expertise?.skills : "No Record Found"}</li>
                                    {/*<li className=""><Link to="">voice acting</Link></li>
                                <li className=""><Link to="">voiceover</Link></li>
                                <li className=""><Link to="">voice over</Link></li>
                                <li className=""><Link to="">voiceover talent</Link></li>
                                <li className=""><Link to="">voice actor</Link></li>
                                <li className=""><Link to="">voicetalent</Link></li>
                                <li className=""><Link to="">voiceacting</Link></li>
                                <li className=""><Link to="">voiceactor</Link></li>
                                <li className=""><Link to="">voiceover artist</Link></li>*/}
                                </ul>
                            </div>
                            <div className="education-list list">
                                <h3>Education</h3>
                                <ul className="mt-2 pl-0">
                                    {profileData.education.length > 0 ? profileData.education.map((itemOfEducation, index) => (
                                        <li key={index} className="interactive-bullet">
                                        <p>{itemOfEducation.degree} | {itemOfEducation.provider}, Graduated {itemOfEducation.to ? itemOfEducation.to.split('-')[0] : ''}</p>
                                       {/* |   <p></p> */}
                                    </li>
                                    )) :
                                        <li className="interactive-bullet">
                                            No Record Found
                                        </li>}

                                </ul>
                            </div>

                            <div className="education-list list">
                                <h3>Certificate</h3>
                                <ul className="mt-2 pl-0">
                                    {profileData.certification?.length > 0 ? profileData.certification?.map((itemofcertification, index) => (
                                       <li className="interactive-bullet" key={index}> 
                                       <p>{itemofcertification.certification_name} | {itemofcertification.provider}</p>
                                       {/* <p>{itemofcertification.provider}</p> */}
                                   </li>
                                    )) :
                                        <li className="interactive-bullet">
                                            No Record Found
                                        </li>}
                                </ul>
                            </div>
                            <div className="education-list list">
                                <h3>Employment</h3>
                                <ul className="mt-2 pl-0">
                                    {profileData.employment.length > 0 ? profileData.employment.map((itemofemployment, index) => (
                                        <li className="interactive-bullet" key={index}>
                                            <p>
                                                {itemofemployment.company_name}
                                                ( <strong>
                                                    {itemofemployment.from.split('T')[0]} -
                                                    {itemofemployment.current ? "Still working" : itemofemployment.to.split('T')[0]}
                                                </strong> )
                                            </p>
                                            <p>{itemofemployment.title}</p>
                                            <p>{itemofemployment.city}, {itemofemployment.country}</p>
                                        </li>
                                    )) :
                                        <li className="interactive-bullet">No Record Found</li>}
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}


const mapStateToProps = (state) => ({
    // auth: state.auth,
    freelancerProfile: state.freelancerProfile,
    // userStats: state.proposalAndContract.userStats,
    // ContractSummary: state.proposalAndContract.ContractSummary
});

export default connect(mapStateToProps, )(PublicProfile);