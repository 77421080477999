import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { Suspense } from "react";

import "./styles/style.scss";
import AppRoutes from "./routes";
import store from "./store";
import ReactGA from "react-ga4";
import CookieConsent from "./CookiesConcent"; 
// import { useNavigation } from '@react-navigation/native';

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";



// App.js or any other entry point
if (process.env.NODE_ENV === 'production') {
  console.error = () => {};
  console.warn = () => {};
}

if (process.env.NODE_ENV === 'development') {
  console.error = () => {};
  console.warn = () => {};
}



const App = () => {
  // const navigation = useNavigation();
  // const { dangerouslyGetState } = useNavigation();
  // const { index, routes } = dangerouslyGetState();

  /*useEffect(() => {
    // const TRACKING_ID = "G-8DV5RW99SF";
    const TRACKING_ID = process.env.REACT_APP_GA_TRACK_API;
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [window?.location?.pathname]);*/

  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API,
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API });


  const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let scrollPosition = document.getElementsByTagName("html")[0].scrollTop;
      if (scrollPosition > 100) {
        document.body.classList.add("sticky-header");
      } else {
        document.body.classList.remove("sticky-header");
      }
    });
  }, []);
  

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Router basename="/">
          <Fragment>
            {/* <Header /> */}
            {/*<Alert />*/}
            <AppRoutes />
            <CookieConsent />
            {/* <Footer /> */}
          </Fragment>
        </Router>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
