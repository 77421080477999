import { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { connect, useDispatch } from "react-redux";
import { updateUserConfig } from "../../actions/auth";

const UserConfiguration = ({ auth: { user }, show, setShow }) => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const defaultValue = {
    visibility: user?.visibility || false,
    active: user?.active || false,
    anonymousMode: user?.anonymousMode || false, 
  };

  const [form, setForm] = useState(defaultValue);

  const handleOpen = () => {
    setShow(true);
  }
  const handleClose = () => {
    setForm(defaultValue);
    setShow(false);
  }

  const handleChangeForm = (e) => {
    setForm(form => {
      return {
        ...form,
        [e.target.name]: e.target.checked
      }
    });
  }
  const handleSubmit = async () => {
    setLoading(true);
    await dispatch(updateUserConfig(form));
    handleClose();
    setLoading(false);
  }

  useEffect(() => {
    setForm({
      visibility: user.visibility ?? false,
      active: user.active ?? false,
      anonymousMode: user.anonymousMode ?? false, 
    });
  }, [user.visibility, user.active, user.anonymousMode]);


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="pb-0">
          <Modal.Title>Account Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="form-text mb-2">
            Account Configuration allows you to personalize and manage your account settings and customize your profile.
          </p>
          <div className="d-flex flex-column">
            <Form>
              <div className="d-flex align-items-center justify-content-between my-2">
                <div className="d-flex flex-column">
                  <Form.Label
                    className="my-0 py-0"
                  >
                    Visibility
                  </Form.Label>
                  <Form.Text
                    className="my-0"
                  >
                    Allows other users to see when you are online.
                  </Form.Text>
                </div>
                <Form.Switch
                  id="visibility-switch"
                  name="visibility"
                  role="button"
                  size="md"
                  readOnly={form.visibility === null}
                  checked={form.visibility || false}
                  onChange={handleChangeForm}
                />
              </div>

              <div
                className={`${user.type === 'Client' ? 'd-none' : 'd-flex'} align-items-center justify-content-between my-2`}
              >
                <div className="d-flex flex-column">
                  <Form.Label
                    className="my-0 py-0"
                  >
                    Active
                  </Form.Label>
                  <Form.Text
                    className="my-0"
                  >
                    Display your profile on the talent page.
                  </Form.Text>
                </div>
                <Form.Switch
                  id="active-switch"
                  name="active"
                  role="button"
                  size="md"
                  readOnly={form.active === null}
                  checked={form.active || false}
                  onChange={handleChangeForm}
                />
              </div>

              <div className="d-flex align-items-center justify-content-between my-2">
                <div className="d-flex flex-column">
                  <Form.Label className="my-0 py-0">Anonymous Mode</Form.Label>
                  <Form.Text className="my-0">Hide your identity on the platform.</Form.Text>
                </div>
                <Form.Switch
                  id="anonymousMode-switch"
                  name="anonymousMode"
                  role="button"
                  size="md"
                  readOnly={form.anonymousMode === null}
                  checked={form.anonymousMode || false}
                  onChange={handleChangeForm}
                />
              </div>

            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            variant="secondary"
            onClick={handleSubmit}
            disabled={loading}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapStateToProp = (state) => {
  return {
    auth: state.auth,
  }
};

export default connect(mapStateToProp)(UserConfiguration);