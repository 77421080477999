import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { SVGFooterLogo, SVGSocial } from "../SVG";
import useWindowSize from "../../customHooks/windowResize";
import React, { useState, useEffect } from "react";

const Footer = ({auth: { isAuthenticated, loading, user }}) => {
  
  const [footerContainer, setFooterContainer] = useState('');
  
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  let windowSize = useWindowSize();

  useEffect(() => {
     if(windowSize.width <= 768){
      setFooterContainer('logo-container-footer');
     }else{
      setFooterContainer('');
     }
  }, [windowSize]);

  return (
    <>
      <footer
        className={`main-footer`}
      >
        <div className=" container">
          <div className="row justify-content-between main-footer--content">
            <div className="col col-md-2_ col-lg-2_ col-xl-2_ col-xxl-2_">
              <div className="footer-logo">
                <div className={footerContainer}>
                  <Link to="" onClick={scrollToTop}>
                  {/*<img src="assets/images/logo-footer.svg" width="150" height="150" />*/}
                  <SVGFooterLogo/>
                </Link>
                </div>
                <span className="logo-bottom-line">&nbsp;</span>

                <ul className="social-icons">
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/in/cybertocyber",
                          "_blank"
                        )
                      }
                    >
                      <SVGSocial icon={`linkedIn`} />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={() =>
                        window.open(
                          "https://twitter.com/cybertocyber",
                          "_blank"
                        )
                      }
                    >
                      <SVGSocial icon={`twiiter_updated`} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-9 col-lg-9 col-xl-9 col-xxl-9">
              <div className="d-flex justify-content-between">
                <div className="footer-list"></div>
                <div className="footer-list" style={{marginBottom:"-15px"}}>
                  <h2>About</h2>
                  <ul className="list">
                    <li>
                      <Link to="/login-register?role=client&type=login" onClick={scrollToTop}>
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/register-role?type=signup" onClick={scrollToTop}>
                        Sign Up
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={scrollToTop}>
                        About Us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-list" style={{marginBottom:"-15px"}}>
                  <h2>Terms and Conditions</h2>
                  <ul className="list">
                    <li>
                      <Link to="/coc" onClick={scrollToTop}>
                        Code of Conduct
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacyPolicy" onClick={scrollToTop}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/contactus" onClick={scrollToTop}>
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="footer-list">
                    {isAuthenticated ? (
                      <>
                        <h2>Quick Links</h2>
                        <ul className="list">
                          <li>
                          {user.type === "Freelancer" ? (
                            <Link to="/profile" onClick={scrollToTop}>
                              Profile
                            </Link>
                          ) : user.type === "Client" ? (
                            <Link  to="/clientProfile" onClick={scrollToTop}>
                              Profile 
                            </Link>
                          ) : null}
                          </li>
                          <li>
                          {user.type === "Freelancer" ? (
                            <Link to="/finance" onClick={scrollToTop}>
                              Earnings
                            </Link>
                          ) : user.type === "Client" ? (
                            <Link  to="/wallet" onClick={scrollToTop}>
                              Wallet
                            </Link>
                          ) : null}
                          </li>
                          <li>
                            <Link to="/contract" onClick={scrollToTop}>
                              Contracts
                            </Link>
                          </li>
                        </ul>
                      </>
                    ) : null}
                  </div>

              </div>
            </div>
          </div>
        </div>
        <div className="copyright container">
          <div>
            <p>
              &copy; Copyright {new Date().getFullYear()} CybertoCyber. All
              Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});


export default connect(mapStateToProps)(Footer);
