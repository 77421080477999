import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "./sweetAlert.css";
import { BlackListMessages } from "./blackListMessages";

const MySwal = withReactContent(Swal);

const useSweetAlert = () => {
  const showAlert = (title, text, type = "info", timerDuration = null) => {
    const options = {
      title,
      text,
      icon: type,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    };

    if (timerDuration) {
      options.timer = timerDuration;
      options.timerProgressBar = true;
    }

    MySwal.fire(options);
  };

  const showConfirmation = async (title, text) => {
    const options = {
      title,
      text,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    };

    const result = await MySwal.fire(options);

    return result.isConfirmed;
  };

  const showToast = async (title, type = "info") => {
    if (type === "danger") {
      type = "error";
    }

    if (BlackListMessages.indexOf(title) >= 0) {
      return;
    }

    const options = {
      toast: true,
      position: "bottom-right",
      iconColor: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 5500,
      timerProgressBar: true,
    };

    Swal.mixin(options).fire({
      title,
      icon: type,
    });
  };

  return { showAlert, showConfirmation, showToast };
};

export default useSweetAlert;
