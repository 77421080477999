import React from 'react';
import './LoadingSpinner.css'; // Import the CSS for styling the spinner

const LoadingSpinner = () => (
  <div className="spinner-container">
    <div className="loading-spinner"></div>
  </div>
);

export default LoadingSpinner;
