import { ADMIN_GET_USERS, ADMIN_GET_USERS_COUNT } from "../actions/types";


const initialState = {
    users:[],
    usersCount: 0,
};

export default function adminGetAllUser(state = initialState, action) {
    const {type, payload} = action;
    
    switch (type){
        case ADMIN_GET_USERS:

            if (typeof payload === "object" && Array.isArray(payload.usersWithExtraDetails)) {
                const newState = {
                    ...state,
                    loading: true,
                    users: [...payload.usersWithExtraDetails],
                    // usersCount: payload.totalUsers || 0, // Provide a default value
                };

                console.log("New state before return:", newState); // Log the new state to debug
                return newState;
            } else {
                console.error("Payload is not an array or is empty");
                return state;
            }

            case ADMIN_GET_USERS_COUNT:
                debugger; // Pause execution to inspect payload
    
                if (typeof payload === "object" && typeof payload.totalUsers === "number") {
                    const newState = {
                        ...state,
                        loading: true,
                        usersCount: payload.totalUsers, // Access totalUsers directly from the payload
                    };
    
                    console.log("New state before return:", newState); // Log the new state to debug
                    return newState;
                } else {
                    console.error("Payload is not valid for users count");
                    return state;
                }
        default:
            return state;
    }

}