import React from "react";
import { Route, useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/footer";
import { ToastContainer } from "react-toastify";
import HomeHeader from "../components/homeheader/HomeHeader";

const MyRoute = (props) => {
  const location = useLocation();

  return (
    <>
      {/* {" "}
      {props.mode? (
        <Header type={props.mode} />
      ) : (
        <Header />
      )} */}
      {
        location.pathname !== "/login-register" &&
        (props.mode ? <Header type={props.mode} /> : <Header />)}
        {/* (props.mode ?
          location.pathname === "/" ? <HomeHeader type={props.mode} /> :  <Header type={props.mode} /> : location.pathname === "/" ? <HomeHeader /> : <Header />)}*/}
      <ToastContainer position="top-right" />
      <Route {...props} />
      {location.pathname !== "/messages" &&
        location.pathname !== "/login" &&
        location.pathname !== "/register" &&
        location.pathname !== "/login-register" &&
        location.pathname !== "/blank" &&
        
        /*location.pathname !== "/question1/individual" &&
        location.pathname !== "/question2/individual" &&
        location.pathname !== "/question3/individual" &&
        location.pathname !== "/question4/individual" &&
        location.pathname !== "/question5/individual" &&
        location.pathname !== "/question6/individual" &&
        location.pathname !== "/question7/individual" &&
        location.pathname !== "/question8/individual" &&
        location.pathname !== "/question9/individual" &&
        location.pathname !== "/question10/individual" &&
        location.pathname !== "/question1/company" &&
        location.pathname !== "/question2/company" &&
        location.pathname !== "/question3/company" &&
        location.pathname !== "/question4/company" &&
        location.pathname !== "/question5/company" &&
        location.pathname !== "/question6/company" &&
        location.pathname !== "/question7/company" &&
        location.pathname !== "/question8/company" &&
        location.pathname !== "/question9/company" &&
        location.pathname !== "/question10/company" &&*/

        location.pathname !== "/ForgetPassword" &&
        location.pathname !== "/register-role" &&
        location.pathname !== "/request-service" &&
        (props.mode ? <Footer type={props.mode} /> : <Footer />)}
    </>
  );
};

export default MyRoute;
