import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment , FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles';
import { SVGWidgetLogo, FormIcon } from "../../components/SVG";

const Input = ({
  parentclass,
  label,
  labelfor,
  type,
  iconType,
  id,
  value,
  name,
  minLength,
  maxLength,
  placeholder,
  group,
  className,
  icon,
  required,
  handlerOnChange,
  children,
}) => {
  // const Input = (props) => {

  // const [cval, setCval] = useState(value);
  const useStyles = makeStyles({
    textField: {
      // Add your custom styles here
      // For example:
      padding: 0,
      color: '#2AAAE1'
    },
  });

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#2AAAE1', //your color
        borderBottomColor: '#2AAAE1'
      }
    }
  });

  const classes = useStyles();

  return (
    <div className={`form-group cyber-form-control ${parentclass}`}>
      {label && (
        <label htmlFor={labelfor} className="form-label">
          {label} {required && <span className="required-field">*</span>}
        </label>
      )}
      {group == true ? (
        <div className="">
          {icon && (
            <span className="input-group-text" id={id}>
              {icon}
            </span>
          )}
          {type === "textarea" ? (

            <TextField
              id={labelfor}
              label={placeholder}
              name={name}
              value={value}
              className={`form-control`}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              onChange={handlerOnChange}
              multiline
              rows={6}
            />
          ) : (
            <ThemeProvider theme={theme}>
           <FormControl variant="standard" style={{width:'100%'}}>
            <TextField
              type={type ? type : "text"}
              id={labelfor}
              name={name}
              value={value}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              label={placeholder}
              onChange={handlerOnChange}
              InputProps={{
                endAdornment: iconType !== null && (
                  <InputAdornment position="end">
                    <FormIcon icon={iconType} />
                  </InputAdornment>
                ),
                style:{
                  fontFamily:"Inter",
                  fontSize:"14px",
                  fontWeight: "600"
                }
              }}
              InputLabelProps={{
                style: {
                  //paddingLeft: "14px", // Equal padding on all sides for the label
                  paddingBottom: "8px",
                  color: '#2AAAE1'
                },
              }}
              inputProps={{
                style: {
                  paddingLeft: "14px",
                },
              }}
            /></FormControl>
            </ThemeProvider>
          )}
          
        </div>
      ) : (
        <>
          {type === "textarea" ? (
            <textarea
              // type={type ? type : 'text'}
              rows={6}
              id={labelfor}
              name={name}
              value={value}
              className={`form-control ${className}`}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={handlerOnChange}
            ></textarea>
          ) : (
            <TextField
              type={type ? type : "text"}
              id={labelfor}
              name={name}
              value={value}
              className={`form-control ${className}`}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              label={placeholder}
              onChange={handlerOnChange}
              InputProps={{
                endAdornment: iconType !== null && (
                  <InputAdornment position="end">
                    <FormIcon icon={iconType} />
                  </InputAdornment>
                ),
                style:{
                  fontFamily:"Inter",
                  fontSize:"14px",
                  fontWeight: "600"
                }
              }}
            />
          )}
        </>
      )}

      {children}
    </div>
  );
};

// Input.propTypes = {
//     group: PropTypes.bool
// }

// Input.defaultProps = {
//     ...ComponentWithDefaultPropDefinitions.defaultProps
//  }

Input.propTypes = {
  // ...propTypes,
  parentclass: PropTypes.string,
  label: PropTypes.string,
  labelfor: PropTypes.any,
  id: PropTypes.any,
  name: PropTypes.string,
  group: PropTypes.bool,
  icon: PropTypes.any,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  // rest: PropTypes.any,
  children: PropTypes.element,
};

export const Input2 = ({
  parentclass,
  label,
  labelfor,
  type,
  id,
  value,
  name,
  minLength,
  maxLength,
  placeholder,
  group,
  className,
  icon,
  required,
  handlerOnChange,
  children,
}) => {
  // const Input = (props) => {

  // const [cval, setCval] = useState(value);

  return (
    <div className={`form-group cyber-form-control ${parentclass}`}>
      {label && (
        <label htmlFor={labelfor} className="form-label">
          {label}
        </label>
      )}
      {group == true ? (
        <div className={`${type === "textarea" ? "" : "input-group"}`}>
          {icon && (
            <span className="input-group-text" id={id}>
              {icon}
            </span>
          )}
          {type === "textarea" ? (
            <textarea
              // type={type ? type : 'text'}
              rows={6}
              id={labelfor}
              name={name}
              value={value}
              className={`form-control ${className}`}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={handlerOnChange}
            ></textarea>
          ) : (
            <input
              type={type ? type : "text"}
              id={labelfor}
              name={name}
              value={value}
              className={`form-control ${className}`}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={handlerOnChange}
            />
          )}
        </div>
      ) : (
        <>
          {type === "textarea" ? (
            <textarea
              // type={type ? type : 'text'}
              rows={6}
              id={labelfor}
              name={name}
              value={value}
              className={`form-control ${className}`}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={handlerOnChange}
            ></textarea>
          ) : (
            <input
              type={type ? type : "text"}
              id={labelfor}
              name={name}
              value={value}
              className={`form-control ${className}`}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={handlerOnChange}
            />
          )}
        </>
      )}

      {children}
    </div>
  );
};

// Input.propTypes = {
//     group: PropTypes.bool
// }

// Input.defaultProps = {
//     ...ComponentWithDefaultPropDefinitions.defaultProps
//  }

Input2.propTypes = {
  // ...propTypes,
  parentclass: PropTypes.string,
  label: PropTypes.string,
  labelfor: PropTypes.any,
  id: PropTypes.any,
  name: PropTypes.string,
  group: PropTypes.bool,
  icon: PropTypes.any,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  // rest: PropTypes.any,
  children: PropTypes.element,
};

export default Input;
