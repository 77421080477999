import React, { Fragment, useState, useEffect } from "react";
import { Link, NavLink, useLocation, Redirect, useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { SVGLogin, SVGSignUp, UserProfileIcon, SettngsIcon, MangeUsersIcon, PayoutIcon, EnterpriseIcon, DisputeIcon, TickMarkIcon, DollarIcon, SignupNav, UserNav, AboutNav, WorkNav, TalentNav, HomeNav, RequestNav } from "../SVG";
import SidebarMenu from 'react-bootstrap-sidebar-menu';
// import NavbarMain from "../navbar/Navbar";
import { Icon } from 'react-icons-kit';
import { remove } from 'react-icons-kit/fa/remove'
import { user } from 'react-icons-kit/fa/user';
import { signIn } from 'react-icons-kit/fa/signIn';
import { userPlus } from 'react-icons-kit/fa/userPlus';
import { laptop } from 'react-icons-kit/fa/laptop';
import { users } from "react-icons-kit/fa/users";
import { check } from 'react-icons-kit/fa/check';
import { fileText } from 'react-icons-kit/fa/fileText';
import { alignLeft } from 'react-icons-kit/fa/alignLeft';
import { signOut } from 'react-icons-kit/fa/signOut';
import { cog } from 'react-icons-kit/fa/cog';
import { dollar } from 'react-icons-kit/fa/dollar';
import { Logo } from "../../components/logo/Logo";
import useWindowSize from "../../customHooks/windowResize";
import { menu } from 'react-icons-kit/icomoon/menu';
import { useDispatch } from 'react-redux';
import { messageSummary } from "../../actions/proposalAndContract";
import { getAdminMessages } from "../../actions/manageUsers";
import { note } from 'react-icons-kit/oct/note'
import { Button, Modal, Form } from "react-bootstrap";
import logoutImage from '../../img/log-out.svg';
import userImage from '../../img/user.png';
import Badge from 'react-bootstrap/Badge';
import UserConfiguration from "../../pages/dashboard/UserConfiguration";
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import LogoMenu from "../logo/LogoMenu";
import { Comment, Home, Note } from "@material-ui/icons";
import about from "./about.svg"
import work from "./work.svg"
import talent from "./talent.svg"
import homes from "./home.svg"
import signins from "./user.svg"
import signup from "./signup.svg"
import request from "./request.svg"
import { Fade } from "react-awesome-reveal";
import { Divider } from "@material-ui/core";
import Hamburger from "hamburger-react";

const Header = ({
  freelancerProfile: { profileAndPhoto },
  auth: { isAuthenticated, loading, user },
  logout,
  type,
  MessageSummary,
  AdminMessagesItemData,
  messageSummary,
  MessagesItemData,
}) => {
  //const Header = ({ auth: { isAuthenticated, loading, user }, logout, type }) => {

  const [userDropdown, setUserDropdown] = useState(false);
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showUserConfigurations, setShowUserConfigurations] = useState(false);

  const location = useLocation();

  // useEffect(() => {
  //   const driverObj = new driver({
  //     showProgress: true,
  //     steps: [
  //       {
  //         element: '#request',
  //         popover: {
  //           title: 'Title',
  //           description: 'Make account ',
  //           side: "left",
  //            align: 'start'
  //         },
  //       },
  //       {
  //         element: '.main-header',
  //         popover: {
  //           title: 'Title',
  //           description: 'Description',
  //           side: "left",
  //            align: 'start'
  //         },
  //       },

  //     ],
  //   });

  //   driverObj.drive();
  // }, []); 

  useEffect(() => {
    //debugger;
    messageSummary();
    dispatch(getAdminMessages());

    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    //debugger;
    messageSummary();
    dispatch(getAdminMessages());
  }, [isAuthenticated]);



  //const user = auth.user;

  // const [windowSize, setWindowSize] = useState({
  //   width: undefined,
  //   height: undefined,
  // });

  // // Check Mobile Responsive
  // useEffect(() => {
  //   // Handler to call on window resize
  //   function handleResize() {
  //     // Set window width/height to state
  //     setWindowSize({
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     });
  //   }
  //   // Add event listener
  //   window.addEventListener("resize", handleResize);
  //   // Call handler right away so state gets updated with initial window size
  //   handleResize();
  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const [showLogout, setShowLogout] = useState(false);

  const handleCloseLogout = () => {
    setShowLogout(false);
  };

  const confirmLogout = () => {
    setShowLogout(false);
    logout();
    window.location.href = "/"; //register-role  //login-register?role=client /pro
  };

  const userLogout = () => {
    setShowLogout(true);
  };

  const history = useHistory();

  const general_check = () => {
    //const value = true;
    history.push(`/wallet`);
  };

  const handleDropdownItemClick = () => {
    if (user.type === "Client" || user.type === "Enterprise Client") {
      general_check();
    } else {
      history.push("/finance");
    }
  };

  const handleDropdownItemClickTrans = () => {
    history.push("/refund");
  };

  const handleNavLinkClick = (event) => {
    event.preventDefault(); // Prevents the default navigation behavior
    general_check();
  };

  const toggleUserDropdown = (e) => {
    e.preventDefault();
    setUserDropdown((prev) => !prev);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      className="btn btn-icon btn-transparent-dark"
      to="#!"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/*<img className="img-fluid" src="assets/images/BilalAhmedProfile.jpg" />*/}
      {profileAndPhoto.photo_link ? (
        <img
          className="rounded-circle"
          src={profileAndPhoto.photo_link}
          alt=""
          style={{ height: "40px", width: "40px" }}
        />
      ) : (
        <div
          className="rounded-circle"
          style={{
            height: "40px",
            width: "40px",
            backgroundColor: "#01131d",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5 className="text-uppercase profile-avatar" style={{ margin: 0 }}>
            {user.first_name.slice(0, 1)}
            {user.last_name.slice(0, 1)}
          </h5>
        </div>
      )}
    </Link>
  ));

  const authLinks = () => {
    return (
      <ul className="navbar-nav align-items-center ml-auto mt-0">
        <UserConfiguration
          show={showUserConfigurations}
          setShow={setShowUserConfigurations}
        />
        <li className="nav-item dropdown no-arrow no-caret dropdown-user p-1">
          <Dropdown>
            {/* <span className="user-name"> {user.first_name} {user.last_name} </span> */}
            <Dropdown.Toggle as={CustomToggle}>Custom toggle</Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item eventKey="1">
                <h6 className="dropdown-header d-flex align-items-center p-1">
                  {/*<img className="dropdown-user-img" src="assets/images/BilalAhmedProfile.jpg" alt="" />*/}
                  <div>
                    {profileAndPhoto.photo_link ? (
                      <img
                        className="rounded-circle mr-2"
                        src={profileAndPhoto.photo_link}
                        alt=""
                        style={{ height: "40px", width: "40px" }}
                      />
                    ) : (
                      <div
                        className="rounded-circle mr-2"
                        style={{
                          height: "40px",
                          width: "40px",
                          backgroundColor: "#01131d",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h7 className="text-uppercase profile-avatar" style={{ margin: 0 }}>
                          {user.first_name.slice(0, 1)}
                          {user.last_name.slice(0, 1)}
                        </h7>
                      </div>
                    )}
                  </div>
                  <div className="dropdown-user-details">
                    <div className="dropdown-user-details-name">
                      {user.first_name} {user.last_name}
                    </div>
                    <div className="dropdown-user-details-email">
                      {user.type === "Freelancer"
                        ? "Cyber Expert Account"
                        : user.type === "Client"
                          ? "Cyber Client Account"
                          : user.type === "Admin"
                            ? "Cyber Admin Account"
                            : user.type === "Enterprise Client"
                              ? "Cyber Enterprise Client" : ""}
                    </div>
                  </div>
                </h6>
              </Dropdown.Item>
              <div className="dropdown-divider"></div>




              {user.type === 'Freelancer' ? (
                <Dropdown.Item className="dropdown-link" eventKey="3" as={Link} to="/profile">
                  <UserProfileIcon />
                  <span className="menu-text">Profile</span>
                </Dropdown.Item>
              ) : (
                user.type === 'Client' ? (
                  <Dropdown.Item className="dropdown-link" eventKey="3" as={Link} to="/clientprofile">
                    <UserProfileIcon /> <span className="mt-1 ml-2">Profile</span>
                  </Dropdown.Item>
                ) : null
              )}


              <Dropdown.Item className="dropdown-link" eventKey="4" as={Link} to="/contract">
                <span className="menu-icon"><Icon icon={note} /></span>
                <span className="menu-text">Contract</span>
              </Dropdown.Item>
              {user.type !== "Admin" && (
                <Dropdown.Item className="dropdown-link" eventKey="7" onClick={() => setShowUserConfigurations(true)}>
                  <SettngsIcon />
                  <span className="menu-text">Configurations</span>
                </Dropdown.Item>
              )}

              <Dropdown.Item className="dropdown-link" eventKey="4" onClick={handleDropdownItemClick}>
                <DollarIcon />
                <span className="menu-text">
                  {user.type === "Enterprise Client" ? "Pay" : (user.type === "Client" ? "Wallet" : "Earnings")}
                </span>
              </Dropdown.Item>

              {user.type === "Client" && (
                <Dropdown.Item className="dropdown-link" eventKey="7" onClick={handleDropdownItemClickTrans}>
                  <span className="menu-icon">
                    <Icon icon={cog} />
                  </span>
                  <span className="menu-text">Transactions</span>
                </Dropdown.Item>
              )}

              {user.type === "Admin" && (
                <Dropdown.Item className="dropdown-link" eventKey="4" as={Link} to="/manage-users">
                  <MangeUsersIcon />
                  <span className="menu-text">Manage Users</span>
                </Dropdown.Item>

              )}

              {user.type === "Admin" && (
                <Dropdown.Item className="dropdown-link" eventKey="4" as={Link} to="/enterprise">
                  <EnterpriseIcon />
                  <span className="menu-text">Enterprise</span>
                </Dropdown.Item>

              )}

              {user.type === "Admin" && (
                <Dropdown.Item
                  className="dropdown-link"
                  eventKey="5"
                  as={Link}
                  to="/admindashboard"
                >
                  <span className="menu-icon">
                    <Icon icon={laptop} />
                  </span>
                  <span className="menu-text">Admin Dashboard</span>
                </Dropdown.Item>
              )}

              {user.type === "Admin" && (
                <Dropdown.Item
                  className="dropdown-link"
                  eventKey="6"
                  as={Link}
                  to="/verification"
                >
                  <TickMarkIcon />
                  <span className="menu-text">Account Verifications</span>
                </Dropdown.Item>
              )}

              {user.type === "Admin" && (
                <Dropdown.Item
                  className="dropdown-link"
                  eventKey="7"
                  as={Link}
                  to="/payout-approval"
                >
                  <PayoutIcon />
                  <span className="menu-text">Payout Approvals</span>
                </Dropdown.Item>
              )}

              {user.type === "Admin" && (
                <Dropdown.Item
                  className="dropdown-link"
                  eventKey="8"
                  as={Link}
                  to="/dispute"
                >
                  <DisputeIcon />
                  <span className="menu-text">Disputes</span>
                </Dropdown.Item>
              )}


              {/* Add other Dropdown.Item components based on user type */}

              {/*<Dropdown.Item className="dropdown-link" eventKey="6" as={Link} href="#!" onClick={userLogout}>*/}
              <div className="dropdown-divider"></div>
              <Dropdown.Item
                className="dropdown-link logout-link"
                eventKey="6"
                as={Link}
                //to="/login"
                onClick={userLogout}
              >
                <span className="menu-icon">
                  <img src={logoutImage} alt="logout" />
                </span>
                <span className="menu-text">Logout</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </li>
      </ul>
    );
  };

  const guestLinks = () => {
    function scrollToTop() {
      const mobileMenu = document.querySelector('.mobile-menu');

      const mainRoot = document.querySelector('body');
      const ourSlider = document.querySelector('.main-slider');
      const manageusers = document.querySelector('.page-dashboard');
      const about = document.querySelector('.page-about');
      const home = document.querySelector('.page-home');

      // mainRoot.style.width = '100vw'; // Reset width when menu closes
      if (manageusers && manageusers.style) {
        manageusers.style.transform = 'translateX(0vw)';
      }
      if (about && about.style) {
        about.style.transform = 'translateX(0vw)';
      }
      if (home && home.style) {
        home.style.transform = 'translateX(0vw)';
      }

      // mainRoot.style.width = '100vw'; // Reset width when menu closes
      //   if (window.location.pathname === '/') {
      //       ourSlider.style.width = '1200px';
      //   } else {
      //       console.error('ourSlider is not defined');
      //   }       mainRoot.style.overflow = 'visible';

      // Close the menu
      setTimeout(() => {
        mobileMenu.classList.remove('open');
      }, 0.1);
      setMobileMenuOpen(false);
      window.scrollTo(0, 0);
    }
    return (
      <>
        <li className="nav-item">
          <NavLink
            to="/register-role?type=signup"
            onClick={scrollToTop}
            className={`nav-link ${window.location.pathname === "/register-role?type=signup" ? "activem" : ""}`}
          >
            {/* <Icon icon={userPlus} />  */}
            <SignupNav className="d-block d-md-none" />

            Signup
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="login-register?role=client&type=login"
            onClick={scrollToTop}
            className={`nav-link ${window.location.pathname === "login-register?role=client&type=login" ? "activem" : ""}`}
          >
            {/* <Icon icon={signIn} />  */}
            {/* <img src={signins} width={17} className="d-block d-md-none" /> */}
            <UserNav />

            Login
          </NavLink>
        </li>
      </>
    );
  };

  const guestLinksMain = () => {
    function scrollToTop() {
      debugger
      const mobileMenu = document.querySelector('.mobile-menu');

      const mainRoot = document.querySelector('body');
      const ourSlider = document.querySelector('.main-slider');
      const manageusers = document.querySelector('.page-dashboard');
      const about = document.querySelector('.page-about');
      const home = document.querySelector('.page-home');

      // mainRoot.style.width = '100vw'; // Reset width when menu closes
      if (manageusers && manageusers.style) {
        manageusers.style.transform = 'translateX(0vw)';
      }
      if (about && about.style) {
        about.style.transform = 'translateX(0vw)';
      }
      if (home && home.style) {
        home.style.transform = 'translateX(0vw)';
      }

      //   mainRoot.style.width = '100vw'; // Reset width when menu closes

      //       if (window.location.pathname === '/') {
      //     ourSlider.style.width = '1200px';
      // } else {
      //     console.error('ourSlider is not defined');
      // }           

      //  mainRoot.style.overflow = 'visible';


      // Close the menu
      setTimeout(() => {
        mobileMenu.classList.remove('open');
      }, 0.1);
      setMobileMenuOpen(false);
      window.scrollTo(0, 0);
    }

    return (
      <React.Fragment>
        {windowWidth <= 767 ? (
          <li className="nav-item">
            <NavLink to="/" onClick={scrollToTop}
              className={`nav-link ml-md-0 ml-4 ${window.location.pathname === "/" ? "activem" : "not-active"}`}
            >
              {/* <Icon icon={signIn} />  */}
              {/* <img src={homes} width={17} className="d-block d-md-none" /> */}
              <HomeNav />

              Home
            </NavLink>
          </li>
        ) : null}
        <li className="nav-item">
          <NavLink to="/about" onClick={scrollToTop}
            className={`nav-link ml-md-0 ml-4 ${window.location.pathname === "/about" ? "activem" : ""}`}
          >
            {/* <Icon icon={signIn} />  */}
            <AboutNav />
            About
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/job" onClick={scrollToTop}
            className={`nav-link ml-md-0 ml-4 ${window.location.pathname === "/job" ? "activem" : ""}`}
          >
            {/* <Icon icon={signIn} />  */}
            {/* <img src={work} width={17} className="d-block d-md-none" />
             */}
                         <WorkNav />

            Find Work
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/talent" onClick={scrollToTop}
            className={`nav-link ml-md-0 ml-4 ${window.location.pathname === "/talent" ? "activem" : ""}`}
          >
            {/* <Icon icon={signIn} />  */}
            <TalentNav />
            Find Talent
          </NavLink>
        </li>
        <li className="nav-item" style={{
          marginRight: window.innerWidth > 1800 ? "24vw" : window.innerWidth > 1650 ? "20vw" : window.innerWidth > 1500 ? "17vw" :
            window.innerWidth > 1300 ? "16vw" : window.innerWidth > 1100 ? "12vw" : null
        }}>
          <NavLink
            to="request-service?role=client&type=login"
            onClick={scrollToTop}

            id="request"
            className={`nav-link ml-md-0 ml-4 ${window.location.pathname === "request-service?role=client&type=login" ? "activem" : ""}`}
          >
            {/* <Icon icon={signIn} />  */}
            {/* <img src={request} width={17} className="d-block d-md-none" /> */}
            <RequestNav />

            Request a Service
          </NavLink>
        </li>
      </React.Fragment>
    );
  };

  const authLinksMain = () => {
    function scrollToTop() {
      const mobileMenu = document.querySelector('.mobile-menu');
      const mainRoot = document.querySelector('body');
      const ourSlider = document.querySelector('.main-slider');
      const manageusers = document.querySelector('.page-dashboard');
      const about = document.querySelector('.page-about');
      const home = document.querySelector('.page-home');

      // mainRoot.style.width = '100vw'; // Reset width when menu closes
      if (manageusers && manageusers.style) {
        manageusers.style.transform = 'translateX(0vw)';
      }
      if (about && about.style) {
        about.style.transform = 'translateX(0vw)';
      }
      if (home && home.style) {
        home.style.transform = 'translateX(0vw)';
      }

      //   mainRoot.style.width = '100vw'; // Reset width when menu closes
      //     if (window.location.pathname === '/') {
      //     ourSlider.style.width = '1200px';
      // } else {
      //     console.error('ourSlider is not defined');
      // }           mainRoot.style.overflow = 'visible';
      // Close the menu
      setTimeout(() => {
        mobileMenu.classList.remove('open');
      }, 0.1);
      setMobileMenuOpen(false);
      window.scrollTo(0, 0);
    }
    return (
      <React.Fragment>
        {user.type == "Freelancer" ? (
          <li className="nav-item">
            <NavLink className={`nav-link ${window.location.pathname === "/job" ? "activem" : ""}`} to="/job" onClick={scrollToTop}>
              Find Work
            </NavLink>
          </li>
        ) : user.type === "Enterprise Client" ? (
          null
        ) : (
          <li className="nav-item">
            <NavLink className={`nav-link ${window.location.pathname === "/talent" ? "activem" : ""}`} to="/talent" onClick={scrollToTop}>
              Find Talent
            </NavLink>
          </li>
        )}
        <li className="nav-item">
          <NavLink className={`nav-link ${window.location.pathname === "/dashboard" ? "activem" : ""}`} to="/dashboard" onClick={scrollToTop}>
            Dashboard
          </NavLink>
        </li>
        {/* 
      <li className="nav-item">
        <NavLink className="nav-link" to="/report">Report</NavLink>
      </li>
      */}
        <li className="nav-item">
          <div className="d-flex align-items-center pr-2">
            <NavLink
              className={`nav-link pr-2 ${window.location.pathname === "/messages" ? "activem" : ""}`}
              to="/messages"
              onClick={scrollToTop}
            >
              Messages
            </NavLink>
            {/*console.log(MessageSummary)*/}
            {/*
            MessageSummary?.message !== user.user_name ?
              (MessageSummary?.message + AdminMessagesItemData?.unread_message_count) > 0 && (
                <span class="badge bg-danger text-white">
                  {console.log("number of msgs", MessageSummary )}
                  {(MessageSummary?.message + AdminMessagesItemData?.unread_message_count) > 99 ? "99+" : (MessageSummary?.message + AdminMessagesItemData?.unread_message_count)}
                </span>
              )
            :
            <></>
              */}
            {MessageSummary?.message ? (
              <span class="badge bg-danger text-white" style={{
                borderRadius: "50px",
                height: "25px",
                width: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                //padding: "0 10px", // Adjust padding if needed
                fontSize: "12px"  // Adjust font size if needed
              }}>
                {AdminMessagesItemData?.unread_message_count ? (
                  MessageSummary?.message +
                  AdminMessagesItemData?.unread_message_count
                ) : (
                  <>
                    {/*console.log("number of msgs", MessageSummary )*/}
                    {MessageSummary?.message}
                  </>
                )}
              </span>
            ) : (
              <></>
            )}
          </div>
        </li>
        {windowWidth > 767 && (
          <li className="nav-item">
            <div className="nav-link username">Hi {user?.user_name}</div>
          </li>
        )}
      </React.Fragment>
    );
  };

  let windowSize = useWindowSize();
  // const [mobileMode, setMobileMode] = useState(false);

  useEffect(() => {
    // if(windowSize.width <= 768){
    //   setMobileMode(true);
    // }else{
    //   setMobileMode(false);
    // }
  }, [windowSize]);



  const HeaderDefault = () => {
    const toggleMobileMenu = () => {
      // debugger
      const mobileMenu = document.querySelector('.mobile-menu');

      const mainRoot = document.querySelector('.main-root');
      const ourSlider = document.querySelector('.main-slider');
      const manageusers = document.querySelector('.page-dashboard');
      const about = document.querySelector('.page-about');
      const home = document.querySelector('.page-home');

      if (mobileMenuOpen) {
        // Close the menu
        setTimeout(() => {
          if (manageusers && manageusers.style) {
            manageusers.style.transform = 'translateX(0vw)';
          }
          if (about && about.style) {
            about.style.transform = 'translateX(0vw)';
          }
          if (home && home.style) {
            home.style.transform = 'translateX(0vw)';
          }
          mobileMenu.classList.remove('open');
          //   mainRoot.style.width = '100vw'; // Reset width when menu closes
          //     if (window.location.pathname === '/') {
          //     ourSlider.style.width = '1200px';
          // } else {
          //     console.error('ourSlider is not defined');
          // }           mainRoot.style.overflow = 'visible';
        }, 300); // Match the CSS transition duration (300ms)
      } else {
        // Open the menu
        setTimeout(() => {
          mobileMenu.classList.add('open');
          if (manageusers && manageusers.style) {
            manageusers.style.transform = 'translateX(-70vw)';
          } if (about && about.style) {
            about.style.transform = 'translateX(-70vw)';
          } if (home && home.style) {
            home.style.transform = 'translateX(-70vw)';
          }
          //   ourSlider.style.width = '50vw ';
          // mainRoot.style.overflow = 'hidden'; 
        }, 300); // Small delay to allow for rendering
      }

      setMobileMenuOpen(!mobileMenuOpen);

    };
    return (
      <>
        <header className={`main-header ${type === 3 ? "light" : ""}`}>
          {/* <NavbarMain /> */}
          <nav className="navbar navbar-expand-lg navbar-light topbar static-top shadow-sm bg-dark-teal osahan-nav-top">
            <div className="container-fluid pr-4 pr-md-5 pl-3 pl-md-5">
              <div className="mobile-row">
                <Logo />
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleMobileMenu}
                  aria-label="Toggle navigation"
                >
                  {/* <span className="navbar-toggler-icon"></span> */}
                  {/* <Icon icon={menu} /> */}
                  {/* <img src={Menu} width={17} className="d-block d-md-none"/> */}
                  <div className={`icon-container ${mobileMenuOpen ? 'openm' : 'close'}`} >
                    <div className="bar top-bar"></div>
                    <div className="bar middle-bar"></div>
                    <div className="bar bottom-bar"></div>
                  </div>

                </button>
              </div>
              <>
                {!loading && (
                  <Fragment>
                    {isAuthenticated ? (
                      <>
                        <ul className="navbar-nav align-items-center ml-auto">
                          {user.type !== "Admin" ? authLinksMain() : null}{" "}
                        </ul>
                        {authLinks()}
                      </>
                    ) : (
                      <>
                        <ul className="navbar-nav align-items-center ml-auto">
                          {guestLinksMain()} {guestLinks()}{" "}
                        </ul>
                      </>
                    )}
                  </Fragment>
                )}
              </>
            </div>
          </nav>
        </header>
      </>
    );
  }; //HeaderDefault

  const HeaderDark = () => {
    return (
      <>
        <header className="main-header dark">
          <nav className="navbar navbar-expand-lg navbar-light topbar static-top shadow-sm bg-dark-teal osahan-nav-top px-0">
            <div className="container-fluid">
              <div className="mobile-row">
                <Logo type={2} />
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <div
                className={
                  "collapse navbar-collapse" + (mobileMenuOpen ? " show" : "")
                }
              >
                {!loading && (
                  <ul className="navbar-nav ml-auto">
                    {isAuthenticated ? (
                      <>
                        {authLinksMain()}
                        {authLinks()}
                      </>
                    ) : (
                      <>
                        {guestLinksMain()}
                        {guestLinks()}
                      </>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </nav>
          <div
            className={"overlay" + (mobileMenuOpen ? " show" : "")}
            onClick={() => setMobileMenuOpen(false)}
          ></div>
        </header>
      </>
    );
  };

  function scrollToTop() {

    const mobileMenu = document.querySelector('.mobile-menu');

    const mainRoot = document.querySelector('body');
    const ourSlider = document.querySelector('.main-slider');
    const manageusers = document.querySelector('.page-dashboard');
    const about = document.querySelector('.page-about');
    const home = document.querySelector('.page-home');

    // mainRoot.style.width = '100vw'; // Reset width when menu closes
    if (manageusers && manageusers.style) {
      manageusers.style.transform = 'translateX(0vw)';
    }
    if (about && about.style) {
      about.style.transform = 'translateX(0vw)';
    }
    if (home && home.style) {
      home.style.transform = 'translateX(0vw)';
    }

    //   if (window.location.pathname === '/') {
    //         ourSlider.style.width = '1200px';
    //     } else {
    //         console.error('ourSlider is not defined');
    //     }     mainRoot.style.overflow = 'visible';

    // Close the menu
    setTimeout(() => {
      mobileMenu.classList.remove('open');
    }, 0.1);
    setMobileMenuOpen(false);

    window.scrollTo(0, 0);
  }
  // Replace this with your actual authentication check

  return (
    <>
      {/*type ?
          <>
          {(type === 1 || type === 3) && <HeaderDefault/> }
          {type === 2 && <HeaderDark/>}
          </>
    :null*/}
      <>
        <Modal
          show={showLogout}
          onHide={handleCloseLogout}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header>
            <Modal.Title>Approve Logout?</Modal.Title>
            <Icon
              className="mx-2 icon-hover"
              icon={remove}
              onClick={handleCloseLogout}
            />
          </Modal.Header>
          <Modal.Body>Are you sure you want to logout?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseLogout}>
              No
            </Button>
            <Button variant="secondary" onClick={confirmLogout}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <div
        id="root"
        className={
          location.pathname === "/login" ||
            location.pathname === "/blank" ||
            location.pathname === "/question1/individual" ||
            location.pathname === "/question2/individual" ||
            location.pathname === "/question3/individual" ||
            location.pathname === "/question4/individual" ||
            location.pathname === "/question5/individual" ||
            location.pathname === "/question6/individual" ||
            location.pathname === "/question7/individual" ||
            location.pathname === "/question8/individual" ||
            location.pathname === "/question9/individual" ||
            location.pathname === "/question10/individual" ||
            location.pathname === "/question1/company" ||
            location.pathname === "/question2/company" ||
            location.pathname === "/question3/company" ||
            location.pathname === "/question4/company" ||
            location.pathname === "/question5/company" ||
            location.pathname === "/question6/company" ||
            location.pathname === "/question7/company" ||
            location.pathname === "/question8/company" ||
            location.pathname === "/question9/company" ||
            location.pathname === "/question10/company" ||
            location.pathname === "/register" ||
            location.pathname === "/ForgetPassword"
            ? "unauthenticated"
            : "authenticated"
        }
      >
        {location.pathname === "/login" ||
          location.pathname === "/blank" ||
          location.pathname === "/question1/individual" ||
          location.pathname === "/question2/individual" ||
          location.pathname === "/question3/individual" ||
          location.pathname === "/question4/individual" ||
          location.pathname === "/question5/individual" ||
          location.pathname === "/question6/individual" ||
          location.pathname === "/question7/individual" ||
          location.pathname === "/question8/individual" ||
          location.pathname === "/question9/individual" ||
          location.pathname === "/question10/individual" ||
          location.pathname === "/question1/company" ||
          location.pathname === "/question2/company" ||
          location.pathname === "/question3/company" ||
          location.pathname === "/question4/company" ||
          location.pathname === "/question5/company" ||
          location.pathname === "/question6/company" ||
          location.pathname === "/question7/company" ||
          location.pathname === "/question8/company" ||
          location.pathname === "/question9/company" ||
          location.pathname === "/question10/company" ||
          location.pathname === "/register" ||
          location.pathname === "/ForgetPassword" ? null : (
          <HeaderDefault />
        )}
        {/* Rest of your component */}
      </div>

      {/* {mobileMenuOpen && ( */}
      <>
        {!loading && (
          <>
            <div className="mobile-menu">
              {/* <Fade delay={100} direction="right" >
              <LogoMenu />
              </Fade>
             <Divider />  */}
              <Fade delay={200} direction="right" >

                {isAuthenticated ? (
                  <>
                    {/* { authLinks()} */}
                    <ul className="navbar-nav align-items-center ml-auto mt-0">
                      <li className="nav-item dropdown no-arrow no-caret dropdown-user p-1">
                        <div className="dropdown-header d-flex p-0">
                          {/*<img className="dropdown-user-img" src="assets/images/BilalAhmedProfile.jpg" alt="" />*/}

                          {profileAndPhoto.photo_link ? (
                            <img
                              className="rounded-circle"
                              src={profileAndPhoto.photo_link}
                              alt=""
                              style={{ height: "40px", width: "40px" }}
                            />
                          ) : (
                            <h5 className="text-uppercase rounded-profile-avatar ml-3">
                              {user.first_name.slice(0, 1)}
                              {user.last_name.slice(0, 1)}
                            </h5>
                          )}

                          <div className="dropdown-user-details">
                            <div className="dropdown-user-details-name m-0 pl-1">
                              {user.first_name} {user.last_name}
                            </div>
                            <div className="dropdown-user-details-email pl-1">
                              {user.email}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div className="dropdown-divider"></div>
                    <ul className="navbar-nav align-items-center ml-auto">
                      {user.type == "Freelancer" ? (
                        <li className="nav-item" onClick={scrollToTop}>
                          <NavLink className={`nav-link ${window.location.pathname === "/profile" ? "activem" : ""}`} to="/profile">
                            <span className="menu-icon">
                              <Icon icon={alignLeft} />
                            </span>
                            <span className="menu-text">Profile</span>
                          </NavLink>
                        </li>
                      ) : (
                        user.type === "Client" && (
                          <li className="nav-item" onClick={scrollToTop}>
                            <NavLink className={`nav-link ${window.location.pathname === "/clientprofile" ? "activem" : ""}`} to="/clientprofile">
                              <span className="menu-icon">
                                <Icon icon={alignLeft} />
                              </span>
                              <span className="menu-text">Profile</span>.
                            </NavLink>
                          </li>
                        )
                      )}

                      <li className="nav-item" onClick={scrollToTop}>
                        <NavLink className={`nav-link ${window.location.pathname === "/contract" ? "activem" : ""}`} to="/contract">
                          <span className="menu-icon">
                            <Icon icon={note} />
                          </span>
                          <span className="menu-text">Contract</span>
                        </NavLink>
                      </li>
                      {user.type !== "Admin" && (
                        <li className="nav-item" onClick={scrollToTop}>
                          <NavLink
                            to={window?.location?.pathname}
                            className={`nav-link ${window.location.pathname === window?.location?.pathname ? "" : ""}`}
                            onClick={() => setShowUserConfigurations(true)}
                          >
                            <span className="menu-icon">
                              <Icon icon={cog} />
                            </span>
                            <span className="menu-text">Configurations</span>
                          </NavLink>
                        </li>
                      )}
                      {/** 
                      <li className="nav-item" onClick={() => setMobileMenuOpen(false)}>
                        <NavLink className="nav-link" to="/settings">
                          <span className="menu-icon"><Icon icon={cog} /></span>
                          <span className="menu-text">Account Settings</span>
                        </NavLink>
                      </li>
                      */}
                      {/*user.type !== "Admin" && <li className="nav-item" onClick={scrollToTop}><NavLink className="nav-link" to="/finance">
                          <span className="menu-icon"><Icon icon={dollar} /></span> 
                          <span className="menu-text">Earnings</span>
                    </NavLink></li>*/}

                      {user.type !== "Admin" && (
                        <>
                          <li className="nav-item" onClick={scrollToTop}>
                            {user.type === "Client" || user.type === "Enterprise Client" ? (
                              <NavLink
                                className={`nav-link ${window.location.pathname === "/wallet" ? "activem" : ""}`}
                                to="/wallet"
                                onClick={handleNavLinkClick}
                              >
                                <DollarIcon />
                                <span className="menu-text">Wallet</span>
                              </NavLink>
                            ) : (
                              <NavLink className={`nav-link ${window.location.pathname === "/finance" ? "activem" : ""}`} to="/finance">
                                <DollarIcon />
                                <span className="menu-text">Earnings</span>
                              </NavLink>
                            )}
                          </li>
                        </>
                      )}

                      {user.type === "Client" && (
                        <>
                          <li className="nav-item" onClick={scrollToTop}>
                            {user.type === "Client" ? (
                              <NavLink className={`nav-link ${window.location.pathname === "/refund" ? "activem" : ""}`} to="/refund">
                                <span className="menu-icon">
                                  <Icon icon={cog} />
                                </span>
                                <span className="menu-text">Transactions</span>
                              </NavLink>
                            ) : null}
                          </li>
                        </>
                      )}

                      {user.type === "Admin" && (
                        <>
                          <li className="nav-item" onClick={scrollToTop}>
                            <NavLink className={`nav-link ${window.location.pathname === "/admindashboard" ? "activem" : ""}`} to="/admindashboard">
                              <span className="menu-icon">
                                <Icon icon={laptop} />
                              </span>
                              <span className="menu-text">Admin Dashboard</span>
                            </NavLink>
                          </li>

                          <li className="nav-item" onClick={scrollToTop}>
                            <NavLink className={`nav-link ${window.location.pathname === "/verification" ? "activem" : ""}`} to="/verification">
                              <TickMarkIcon />
                              <span className="menu-text">
                                Account Verifications
                              </span>
                            </NavLink>
                          </li>

                          <li className="nav-item" onClick={scrollToTop}>
                            <NavLink className={`nav-link ${window.location.pathname === "/manage-users" ? "activem" : ""}`} to="/manage-users">
                              <MangeUsersIcon />
                              <span className="menu-text">Manage Users</span>
                            </NavLink>
                          </li>

                          <li className="nav-item" onClick={scrollToTop}>
                            <NavLink className={`nav-link ${window.location.pathname === "/enterprise" ? "activem" : ""}`} to="/enterprise">
                              <EnterpriseIcon />
                              <span className="menu-text">Enterprise</span>
                            </NavLink>
                          </li>

                          <li className="nav-item" onClick={scrollToTop}>
                            <NavLink className={`nav-link ${window.location.pathname === "/payout-approval" ? "activem" : ""}`} to="/payout-approval">
                              <PayoutIcon />
                              <span className="menu-text">
                                Payout Approvals
                              </span>
                            </NavLink>
                          </li>

                          <li className="nav-item" onClick={scrollToTop}>
                            <NavLink className={`nav-link ${window.location.pathname === "/finance" ? "activem" : ""}`} to="/finance">
                              <DollarIcon />
                              <span className="menu-text">Earnings</span>
                            </NavLink>
                          </li>

                          <li className="nav-item" onClick={scrollToTop}>
                            <NavLink className={`nav-link ${window.location.pathname === "/dispute" ? "activem" : ""}`} to="/dispute">
                              <DisputeIcon />
                              <span className="menu-text">Disputes</span>
                            </NavLink>
                          </li>
                        </>
                      )}

                      <li className="nav-item" onClick={scrollToTop}>
                        <NavLink
                          className="nav-link logout-link"
                          to={location.pathname}
                          onClick={userLogout}
                        >
                          <span className="menu-icon">
                            <img src={logoutImage} alt="logout" />
                          </span>
                          <span className="menu-text">Logout</span>
                        </NavLink>
                      </li>
                    </ul>

                    <div className="dropdown-divider"></div>

                    <ul className="navbar-nav align-items-center ml-auto">
                      {user.type !== "Admin" ? authLinksMain() : null}
                    </ul>
                  </>
                ) : (
                  <>
                    <ul className="navbar-nav align-items-center ml-auto">
                      {guestLinksMain()} {guestLinks()}
                    </ul>
                  </>
                )}
              </Fade>
            </div>
            {mobileMenuOpen && (<div className="overlay" onClick={scrollToTop}></div>)}
          </>
        )}
      </>
      {/* )} */}
    </>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  type: PropTypes.number,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  freelancerProfile: state.freelancerProfile,
  MessageSummary: state.proposalAndContract?.MessageSummary,
  AdminMessagesItemData: state.proposalAndContract?.AdminMessagesItemData,
  MessagesItemData: state.proposalAndContract?.MessagesItemData,
});

export default connect(mapStateToProps, { logout, messageSummary })(Header);