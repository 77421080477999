import axios from 'axios';
import { setAlert } from './alert';
import {
	ATTACHMENT_GET,
	CONTRACT_GET,
	MESSAGE_GET,
	MESSAGE_GET_SUMMARY,
	PROPOSAL_GET,
	PROPOSALCHECK_GET,
	CLIENTPROPOSALS_GET,
	PROPOSALSTATUSCATALOG_GET,
	CLIENTPROPOSALSUSERNAME_GET,
	CONTRACT_GET_BY_ID,
	CONTRACT_GET_SUMMARY,
	RELEASE_PAYMENT,
	GIVE_FEEDBACK,
	USER_STATS
} from './types';
import setAuthToken from '../utils/setAuthToken';
import { toast } from 'react-toastify';


// Attachment Get
export const attachmentGet = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/attachment');

		if (res.data == null)
			res.data = [];

		dispatch({
			type: ATTACHMENT_GET,
			payload: res.data
		});

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// Attachment Get by Id
export const attachmentGetbyId = (id) => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/attachment/' + id);

		if (res.data == null)
			res.data = [];

		dispatch({
			type: ATTACHMENT_GET,
			payload: res.data
		});

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// Attachment Delete 
export const attachmentDelete = (id) => async dispatch => {

	try {
		const res = await axios.delete('/api/attachment/' + id);
		dispatch(setAlert("Job Delete Successfully", 'success'));
		dispatch(attachmentGet());

	} catch (err) {

		const errors = err.response;
		if (errors) {
			errors.forch(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}

}

// Attachment Add 
export const attachmentAdd = ({ message_id, attachment_link }) => async dispatch => {
		const config = {
			headers: {
				'Content-Type': 'Application/json'
			}
		}

		const body = JSON.stringify({
			message_id,
			attachment_link
		});

		try {
			const res = await axios.post('/api/attachment', body, config);

			dispatch(attachmentGet());

		} catch (err) {

			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
			}

		}

	}


// Contract Get
export const contractGet = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/contract');

		if (res.data.data == null)
			res.data.data = [];
            
			//console.log(res.data.data)
			//debugger;
			dispatch({
				type: CONTRACT_GET,
				payload: res.data.data
			});



	} catch (err) {

		dispatch({
			type: CONTRACT_GET,
			payload: []
		});

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

//Verify Contract status
export const contractVerify = (data,cb) => async dispatch => {
	
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.post('/api/contract/verify', data);

		cb && cb(res.data);
		dispatch(contractGet());
		dispatch(setAlert(res.data.msg, 'success'));

	}catch(e) {
		const error = e.response.data;
		//toast.error(error.msg);
		dispatch(setAlert(error.msg, 'danger'))
	}
};


// Contract Get by Id
export const contractGetbyId = (id) => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		//debugger;
		const res = await axios.get('/api/contract/' + id);
		localStorage.setItem('ContractData', JSON.stringify(res.data));

		if (res.data == null)
			res.data = [];

			//console.log(res.data)
			dispatch({
			type: CONTRACT_GET_BY_ID,
			payload: res.data
		});

		return res;
		

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// Contract Delete 
export const contractDelete = (del_id) => async dispatch => {

	try {
		//debugger;
		//console.log(del_id);
		const res = await axios.delete('/api/contract/'+ del_id)
		.then((res)=>{
           if(res){
			dispatch(contractGet());
			dispatch(setAlert(res.data.msg, 'success'));
		   }
		});
		//console.log(res.data.msg);
		//toast.success("Job Delete Successfully")
		//console.log(res.data.msg);
		

	} catch (err) {

		//console.log(err);
		const errors = err.response;
		if (errors) {
			errors.forch(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}

}

export const revokeContract = (contract_id) => async dispatch => {
	try {
		
		const revoke = await axios.patch('/api/contract/revoke/' + contract_id);

		dispatch(contractGet());
		dispatch(setAlert(revoke.data?.msg || 'Success!', 'success'));

	} catch (error) {
		const errMessage = error?.response?.data?.msg ?? error.message;
		dispatch(setAlert(errMessage, 'danger'));
	}
}

export const revokeMilestone = (contract_id) => async dispatch => {
	try {
		
		const revoke = await axios.patch('/api/contract/revokepayment/' + contract_id);

		dispatch(setAlert(revoke.data?.msg || 'Success!', 'success'));

	} catch (error) {
		const errMessage = error?.response?.data?.msg ?? error.message;
		dispatch(setAlert(errMessage, 'danger'));
	}
}

export const revokeinitial = (contract_id) => async dispatch => {
	try {
		
		const revoke = await axios.patch('/api/contract/revokeinitial/' + contract_id);

		dispatch(setAlert(revoke.data?.msg || 'Success!', 'success'));

	} catch (error) {
		const errMessage = error?.response?.data?.msg ?? error.message;
		dispatch(setAlert(errMessage, 'danger'));
	}
}

// Contract Add
export const contractAdd = (data,cb) => async dispatch => {
		const config = {
			headers: {
				'Content-Type': 'Application/json'
			}
		}

		const body = JSON.stringify(data);

		try {
			//debugger;
			const res = await axios.post('/api/contract', body, config);
			//toast.success(res.data.msg);
			dispatch(setAlert(res.data.msg, 'success'))
			cb && cb();
		} catch (err) {
			const errors = err.response.data;

			if (errors) {
				//toast.error(errors.msg);
				dispatch(setAlert(errors.msg, 'danger'))
			}

		}

	}

// Contract summary(count, total)
export const contractGetSummary = () => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/contract/summary');

		if (res.data == null)
			res.data = [];
		
		dispatch({
			type: CONTRACT_GET_SUMMARY,
			payload: res.data
		});
		
		return res.data;
	} catch (err) {

		dispatch({
			type: CONTRACT_GET_SUMMARY,
			payload: []
		});

		const errors = err.response?.data || err.message;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'));
		}
	}
}

// add milestone
export const addMilestone = (contract_id, title, value, description = '') => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		
		const config = {
			headers: {
				'Content-Type': 'Application/json'
			}
		}
		const body = JSON.stringify({
			contract_id,
			title,
			value,
			description
		});
		const res = await axios.post('/api/contract/milestones', body, config);

		dispatch(contractGetbyId(contract_id));
		dispatch(setAlert(res.data.msg, 'success'));

	} catch (error) {
		const errorMessage = error.response?.data.msg || error.message;
		dispatch(setAlert(errorMessage, 'danger'));
	}
}


// Message Get
export const messageGet = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/message');

		if (res.data == null)
			res.data = [];

		dispatch({
			type: MESSAGE_GET,
			payload: res.data
		});
		
		dispatch(messageSummary());

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// Message Get by Id
export const messageGetbyId = (id) => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		//debugger;
		const res = await axios.get('/api/message/' + id);
		//console.log(res.data);

		if (res.data == null)
			res.data = [];

		dispatch({
			type: MESSAGE_GET,
			payload: res.data
		});

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// Message Delete 
export const messageDelete = (id) => async dispatch => {

	try {
		const res = await axios.delete('/api/message/' + id);
		dispatch(setAlert("Job Delete Successfully", 'success'));
		dispatch(messageGet());

	} catch (err) {

		const errors = err.response;
		if (errors) {
			errors.forch(error => dispatch(setAlert(error.msg, 'danger')))
		}
	}
}

//message Read

export const messageRead = (user,id,cb) => async dispatch => {
	try {
		await axios.put('/api/message/read/' + id);  dispatch(messageSummary());
		dispatch(messageGetbyId(id));
		//debugger;
		dispatch(proposalGetByClient(user));
			
		cb && cb();
	} catch (err) {
		const errors = err.response;
		if (errors) {
			errors.forch(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}
}

// Message Add
export const messageAdd = ({ freelancer_id,
	hire_manager_id,
	user_name,
	message_text,
	message_type,
	message_file_properties,
	proposal_id,
	proposal_catalog_status_id,
    user }) => async dispatch => {
		const config = {
			headers: {
				'Content-Type': 'Application/json'
			}
		}

		const body = JSON.stringify({
			freelancer_id,
			hire_manager_id,
			user_name,
			message_text,
			message_type,
			message_file_properties,
			proposal_id,
			proposal_catalog_status_id,
			user
		});

		try {
			//console.log(body.mess);
			const res = await axios.post('/api/message', body, config);

			// dispatch(messageGet());
			dispatch(proposalGetByClient(user));

		} catch (err) {

			const errors = err.response.data.errors;

			if (errors) {
				errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
			}

		}

	}

// Message summary
export const messageSummary = () => async dispatch => {

	if (!localStorage.token) {
        dispatch({
            type: MESSAGE_GET_SUMMARY,
            payload: {
                message: ''  //No token available
            }
        });
        return;
    }

    setAuthToken(localStorage.token);

	try {
		
		const res = await axios.get('/api/message/summary');

		if (res.data == null)
			res.data = [];

		dispatch({
			type: MESSAGE_GET_SUMMARY,
			payload: res.data
		});

	} catch (err) {
		dispatch({
			type: MESSAGE_GET_SUMMARY,
			payload: {
				message: 0
			}
		});
	}
}


// Proposal Get
export const proposalGet = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/proposal');

		if (res.data == null)
			res.data = [];

		dispatch({
			type: PROPOSAL_GET,
			payload: res.data
		});

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// Proposal Get by Id
export const proposalGetbyId = (id) => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/proposal/' + id);

		if (res.data == null)
			res.data = [];

		dispatch({
			type: PROPOSAL_GET,
			payload: res.data
		});

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// Proposal check by Id
export const proposalCheckbyId = (id,body) => async dispatch => {
	
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}
	
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	try {
		//debugger;
		const res = await axios.post('/api/proposal/check/' + id,JSON.stringify(body),config);

		if (res.data == null)
			res.data = [];

		dispatch({
			type: PROPOSALCHECK_GET,
			payload: res.data
		});

		return res.data

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger')) 
		}
	}
}


// Proposal get by clients
export const proposalGetByClient = (userinfo,cb) => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		
		if (!userinfo?._id || !userinfo?.type) {
			return;
		}

		const res = await axios.get(`/api/proposal/getproposalbyclient/${userinfo._id}/${userinfo.type}`);
		
		//dispatch(messageSummary());

		if (res.data == null)
			res.data = [];

		dispatch({
			type: CLIENTPROPOSALS_GET,
			payload: res.data
		});

		cb && cb();

	} catch (err) {

		dispatch({
			type: CLIENTPROPOSALS_GET,
			payload: []
		});

		const errorMessage = err?.response?.data?.msg || err.message;

		dispatch(setAlert(errorMessage, 'danger'))
	}
}

// Proposal get by clients
export const GetProposalUserName = (id) => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/profile/getUserName/' + id);

		dispatch({
			type: CLIENTPROPOSALSUSERNAME_GET,
			payload: res.data
		});


	} catch (err) {

		const errors = err;


		if (errors) {
			dispatch(setAlert(errors, 'danger'))
		}
	}
}

// Proposal Delete 
export const proposalDelete = (id) => async dispatch => {

	try {
		const res = await axios.delete('/api/proposal/' + id);
		dispatch(setAlert("Job Delete Successfully", 'success'));
		dispatch(proposalGet());

	} catch (err) {

		const errors = err.response;
		if (errors) {
			errors.forch(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}

}

// Proposal Add
export const proposalAdd = ({ job_id,
	freelancer_id,
	hire_manager_id,
	payment_type_id,
	payment_amount,
	current_proposal_status,
	client_grade,
	client_comment,
	user_name,
	freelancer_grade,
	freelancer_comment ,
    read,
    sender_type},cb) => async dispatch => {
		const config = {
			headers: {
				'Content-Type': 'Application/json'
			}
		}

		const body = JSON.stringify({
			job_id,
			freelancer_id,
			hire_manager_id,
			payment_type_id,
			payment_amount,
			current_proposal_status,
			client_grade,
			client_comment,
			user_name,
			freelancer_grade,
			freelancer_comment,
			read,
			sender_type
		});

		try {
			await axios.post('/api/proposal', body, config);
			dispatch(setAlert("Proposal Submit Successfully", 'success'));
			dispatch(proposalGet());
			cb && cb();

		} catch (err) {
			const errors = err.response.data.errors;
	        dispatch(setAlert(err.response.data.msg, 'danger'))
			

		}

	}



// ProposalStatusCatalog Get
export const proposalStatusCatalogGet = () => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/proposalStatusCatalog');

		if (res.data == null)
			res.data = [];

		dispatch({
			type: PROPOSALSTATUSCATALOG_GET,
			payload: res.data
		});

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// ProposalStatusCatalog Get by Id
export const proposalStatusCatalogGetbyId = (id) => async dispatch => {

	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/proposalStatusCatalog/' + id);

		if (res.data == null)
			res.data = [];

		dispatch({
			type: PROPOSALSTATUSCATALOG_GET,
			payload: res.data
		});

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

// ProposalStatusCatalog Delete 
export const proposalStatusCatalogDelete = (id) => async dispatch => {

	try {
		const res = await axios.delete('/api/proposalStatusCatalog/' + id);
		dispatch(setAlert("Job Delete Successfully", 'success'));
		dispatch(proposalStatusCatalogGet());

	} catch (err) {

		const errors = err.response;
		if (errors) {
			errors.forch(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}

}

// ProposalStatusCatalog Add
export const proposalStatusCatalogAdd = ({ status_name }) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const body = JSON.stringify({ status_name });

	try {
		const res = await axios.post('/api/proposalStatusCatalog', body, config);

		dispatch(proposalStatusCatalogGet());

	} catch (err) {

		const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}

}

export const proposalRead = (user,id,cb) => async dispatch => {
	try {
		await axios.put('/api/proposal/read/' + id);
		//dispatch(messageGetbyId(id));
		//debugger;
		dispatch(proposalGetByClient(user));
		
		cb && cb();
	} catch (err) {
		const errors = err.response;
		if (errors) {
			errors.forch(error => dispatch(setAlert(error.msg, 'danger')))
		}

	}
}



export const releasePaymentAction = (contract_id, total_hours, milestone_id = '') => async dispatch => {
	
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const body = JSON.stringify({ contract_id, total_hours, milestone_id });

	try {
		const res = await axios.post('/api/contract/payment_release/' + contract_id, body, config);

		if (res.data == null)
			res.data = [];

		dispatch({
			type: RELEASE_PAYMENT,
			payload: res.data
		});

		const contract = await axios.get('/api/contract/' + contract_id);

		if (contract.data == null)
			contract.data = [];

		dispatch({
			type: CONTRACT_GET_BY_ID,
			payload: contract.data
		});

		dispatch(setAlert(res.data.msg, 'success'));

		return;

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'));
		}
	}

}

export const endContract = (contract_id) => async dispatch => {
	
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.patch('/api/contract/end/' + contract_id);

		if (res.data == null)
			res.data = [];

		const contract = await axios.get('/api/contract/' + contract_id);

		if (contract.data == null)
			contract.data = [];

		dispatch({
			type: CONTRACT_GET_BY_ID,
			payload: contract.data
		});

		dispatch(setAlert(res.data.msg, 'success'));

		return;

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'));
		}
	}

}

export const allow_release = (contract_id) => async dispatch => {
	
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.post('/api/contract/allow_release/' + contract_id);

		if (res.data == null)
			res.data = [];
		
		dispatch(setAlert("Payment refunded successfully", 'success'));
		return;
		
	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'));
		}
	}

}

export const giveFeedback = (contract_id, feedback, satisfaction_rating, delivery_rating) => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	const config = {
		headers: {
			'Content-Type': 'Application/json'
		}
	}

	const body = JSON.stringify({
		contract_id,
		feedback,
		satisfaction_rating,
		delivery_rating
	});

	try {
		const res = await axios.post('/api/contract/feedback/' + contract_id, body, config);

		if (res.data == null)
			res.data = [];

		dispatch({
			type: GIVE_FEEDBACK,
			payload: res.data
		});

		const contract = await axios.get('/api/contract/' + contract_id);

		if (contract.data == null)
			contract.data = [];

		dispatch({
			type: CONTRACT_GET_BY_ID,
			payload: contract.data
		});

		return;

	} catch (err) {

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'))
		}
	}
}

export const getUserStats = () => async dispatch => {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	try {
		const stats = await axios.get('/api/profile/stats');
		const result = stats.data;

		dispatch({
			type: USER_STATS,
			payload: result
		});

	} catch (err) {

		dispatch({
			type: USER_STATS,
			payload: []
		});

		const errors = err.response.data;

		if (errors) {
			dispatch(setAlert(errors.msg, 'danger'));
		}
	}
}