import axios from "axios";
import {ADMIN_GET_USERS, ADMIN_GET_USERS_COUNT, GET_ADMIN_MESSAGES} from "./types";
import { setAlert } from "./alert";


export const adminGetUsers = (userType, page, limit, search, banned) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    try {
        const res = await axios.post(`/api/admin/users/getUsers?page=${page}&limit=${limit}`, {search, page,limit, userType, isbanned: banned }, config);

        if (!res.data) {
            res.data = { usersWithExtraDetails: [], totalUsers: 0 };
        }
    

        dispatch({
            type: ADMIN_GET_USERS,
            payload: {
                usersWithExtraDetails: res.data.usersWithExtraDetails || [],
                // totalUsers: res.data.totalUsers || 0
            }
        });
        debugger

        dispatch({
            type: ADMIN_GET_USERS_COUNT,
            payload: {
                // usersWithExtraDetails: res.data.usersWithExtraDetails || [],
                totalUsers: res.data.totalUsers || 0
            }
        });

       
    } catch (err) {
        const errors = err.response?.data?.errors;

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
    }
};

export const adminSendMessage = ({user_id, message_text})=> async dispatch =>{
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		},
	}

    try {
        const res = await axios.post('/api/admin/users/sendMessage', {user_id, message_text},config);

        if(res.data == null){
            res.data = [];
        }

        dispatch(setAlert(res.data.message, "success"));
    }catch(err){
        const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
    }
}

export const adminSendEmail = ({user_email, email_text})=> async dispatch =>{
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		},
	}

    try {
        const res = await axios.post('/api/admin/users/sendEmail', {user_email, email_text},config);

        if(res.data == null){
            res.data = [];
        }

        dispatch(setAlert(res.data.message, "success"));
    }catch(err){
        const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
    }
}

export const banTheUser = ({ban_user_id})=> async dispatch =>{
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		},
	}

    try {
        const res = await axios.post('/api/admin/users/banUser', {ban_user_id},config);

        if(res.data == null){
            res.data = [];
        }

        dispatch(adminGetUsers("all"));
        dispatch(setAlert(res?.data?.message, "success"));
    }catch(err){
        const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
    }

}

export const unbanTheUser = ({unban_user_id})=> async dispatch =>{
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		},
	}

    try {
        const res = await axios.post('/api/admin/users/unbanUser', {unban_user_id},config);

        if(res.data == null){
            res.data = [];
        }

        dispatch(adminGetUsers("all"));
        dispatch(setAlert(res?.data?.message, "success"));
    }catch(err){
        const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
    }

}


export const getAdminMessages = ()=> async dispatch =>{

    if (!localStorage.token) {
        dispatch({
            type: GET_ADMIN_MESSAGES,
            payload: {
                message: ''  //No token available
            }
        });
        return; 
    }

    const config = {
		headers: {
			'Content-Type': 'Application/json'
		},
	}

    try {
        const res = await axios.post('/api/admin/users/getAdminMessages',config);

        var adminData = {}
        if(res.data == null || (res.data?.message?.length == 0)){
            res.data = [];
        }else{
            adminData = {
                "username": "Admin",
                "type": "Admin",
                "adminMessages": [],
                "lastmsg_time":"10:17",
                "lastmsg_date":"2024-03-05T13:55:35.568Z",
                "lastmsg_text": "",
                "prop_read": false,
                "unread_message_count": 0,
                "sender_type": "Admin",
            }
            let unreadMessages = 0;
            if(res.data?.message?.length > 0){
                for (let messages = 0; messages < res.data?.message.length; messages++) {
                  const element = res.data?.message[messages];
                  if(element.read === false){
                    unreadMessages += 1;
                  }
                }
                
                const messages = res.data.message;
                adminData.adminMessages = res.data?.message;
                adminData.lastmsg_text = res.data?.message[messages.length - 1]?.message_text;
                adminData.lastmsg_time = res.data?.message[messages.length - 1]?.message_time;
                adminData.lastmsg_date = res.data?.message[messages.length - 1]?.message_date;
                adminData.prop_read = unreadMessages > 0 ? false : true;
                adminData.unread_message_count = unreadMessages;
            }
        }
        
        dispatch({
            type: GET_ADMIN_MESSAGES,
            payload: adminData,
        })
    }catch(err){
        const errors = err?.response?.data?.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error?.msg, 'danger')))
		}
    }
}


export const readAdminMessages = (id)=> async dispatch =>{
    const config = {
		headers: {
			'Content-Type': 'Application/json'
		},
	}

    try {
        const res = await axios.put('/api/admin/users/adminMessagesRead/' + id,config);
        dispatch(getAdminMessages());

    }catch(err){
        const errors = err.response.data.errors;

		if (errors) {
			errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
		}
    }
}

