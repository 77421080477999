import classes from "./registerRole.module.css";
import correct from "./images/correct_icon.svg";

export default function BtnRole({
  id,
  img,
  title,
  content,
  selected,
  onClickHandler,
}) {
  let correctIcon = null;
  let seletedClass = "";
  if (selected == id) {
    correctIcon = (
      <div className={classes.correct}>
        <img src={correct} alt="correct" />
      </div>
    );

    seletedClass = classes.selected;
  }

  return (
    <button
      className={`${classes.card} ${seletedClass}`}
      onClick={() => onClickHandler(id)}
    >
      <div className={classes.top}>
        <img src={img} alt={title} />
      </div>
      <div className={classes.title}>{title}</div>
      <div className={classes.content}>{content}</div>
      {correctIcon}
    </button>
  );
}
