import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { remove } from "react-icons-kit/fa/remove";
import { Icon } from "react-icons-kit";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CookieConsent = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [marketingCookies, setMarketingCookies] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    necessary: false,
    preference: false,
  });

  // Check local storage on component mount
  useEffect(() => {
    const savedConsent = localStorage.getItem('cookiesAccepted');
    const savedMarketingCookies = localStorage.getItem('marketingCookies') === 'true';
    if (savedConsent === 'true') {
      setCookiesAccepted(true);
      initializeGoogleAnalytics();
    }
    setMarketingCookies(savedMarketingCookies);
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    localStorage.setItem('marketingCookies', marketingCookies ? 'true' : 'false');
    setCookiesAccepted(true);
    initializeGoogleAnalytics();
    setShowPreferences(false); // Close popup after accepting
  };

  const handleOpenPreferences = () => {
    setShowPreferences(true);
  };

  const handleClosePreferences = () => {
    setShowPreferences(false);
  };

  const initializeGoogleAnalytics = () => {
    const TRACKING_ID = process.env.REACT_APP_GA_TRACK_API;
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  // Return nothing if cookies have been accepted
  if (cookiesAccepted) {
    return null;
  }

  return (
    <>
      <div class="cookie-overlay"></div>
      <div className="cookie-consent-banner">
        <p>
          By clicking “Accept All Cookies”, you agree to the storing of cookies on your device
          to enhance site navigation, analyze site usage, and assist in our marketing efforts.
          Click "Preferences" to customize your cookie settings.
        </p>
        <div className="buttons">
          <button className="btn btn-secondary" onClick={handleOpenPreferences}>
            Preferences
          </button>
          <button className="btn btn-default" onClick={handleAcceptCookies}>
            Accept All 
          </button>
        </div>

        {showPreferences && (
          <div className="cookie-preferences-overlay">
            <div className="cookie-preferences-popup">
              {/* Close button (Cross in top-right corner) */}
              <Icon
                className="mx-2 close-btn"
                icon={remove}
                onClick={handleClosePreferences}
              />
              <div className="popup-content">
                <h3 className='text-primary'>Cookie Preferences</h3>
                <div className="cookie-option">
                  <label>
                    <input type="checkbox" checked disabled /> Necessary Cookies
                    <span className="always-on">(Always On)</span>
                  </label>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="necessary-content" id="necessary-header">
                    <Typography>Show Details</Typography>
                  </AccordionSummary>
                          <AccordionDetails>
                    <Typography className='mb-4'>Our website uses necessary cookies to ensure optimal functionality and enhance your experience. These cookies are essential for the site to operate effectively, as they allow us to remember your preferences and settings for a seamless, secure browsing experience.</Typography>
                    <Typography variant="h6" >These cookies enable:</Typography>
                    <List>
                      <ListItem>Tracking your cookie preferences to honor your choices</ListItem>
                      <ListItem>Maintaining session progress on projects or tasks, ensuring continuity when you return</ListItem>
                    </List>
                  </AccordionDetails>

                </Accordion>
                </div>
                <div className="cookie-option">
                  <label>
                    <input
                      type="checkbox"
                      checked={marketingCookies}
                      onChange={() => setMarketingCookies(!marketingCookies)}
                    />{' '}
                    Extra Cookies
                  </label>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography>Show Details</Typography>
                    </AccordionSummary>
                    {/** <AccordionDetails>
                      <Typography>
                        We can also use extra cookies which have different purposes. If you (or if you're a child, your grown-up) want to turn these extra cookies off, you can turn them off below:
                      </Typography>
                      <Typography>Extra Cookies help things work better.</Typography>

                      <Typography variant="h6" gutterBottom>Functionality Cookies</Typography>
                      <Typography>
                        "Functionality" cookies are an example of Extra Cookies that help us:
                      </Typography>
                      <List>
                        <ListItem>Remember the way you like things to look and work to help you explore more easily (optional preferences such as colour schemes, character icons, etc.).</ListItem>
                        <ListItem>Remember whether something has already been provided to you (so you don't need to see it again).</ListItem>
                        <ListItem>View some types of video content.</ListItem>
                        <ListItem>Remember what city you are in for the purpose of setting the correct language.</ListItem>
                      </List>

                      <Typography variant="h6" gutterBottom>Performance or Analytics Cookies</Typography>
                      <Typography>
                        These are another example of Extra Cookies that help us:
                      </Typography>
                      <List>
                        <ListItem>Understand how people use our Online Services and what bits they like best, to help us make them better.</ListItem>
                        <ListItem>Understand what adverts are most popular (across all our users - not you personally).</ListItem>
                        <ListItem>Make sure we don't show you the same advert lots of times.</ListItem>
                      </List>

                      <Typography>For more information about how we use cookies, please see our cookies policy.</Typography>
                    </AccordionDetails>*/}
                    <AccordionDetails>
 
                    <Typography className='mb-4'>Extra cookies can help us improve functionality and personalization.</Typography>

                    <Typography variant="h6" gutterBottom>Functionality Cookies</Typography>
                    <Typography>
                      "Functionality" cookies are one type of extra cookie that allows us to:
                    </Typography>
                    <List>
                      <ListItem>Remember your preferences, such as layout and theme settings, to make navigation smoother.</ListItem>
                      <ListItem>Enable access to specific multimedia content.</ListItem>
                      
                    </List>
                  </AccordionDetails>

                  </Accordion>
                </div>
                <div className="popup-buttons">
                  <button className="btn btn-secondary" onClick={handleAcceptCookies}>
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div></>
  );
};

export default CookieConsent;
