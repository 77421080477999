import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { loadUser, PhoneNumberGet } from "../actions/auth";
import { hireManagerGet } from "../actions/clientProfile";
import LoadingSpinner from './LoadingSpinner';

import {
  CertificateGet,
  EducationGet,
  EmploymentGet,
  expertiseGet,
  expertLevelGet,
  HourlyRateGet,
  LanguageGet,
  locationGet,
  ProfileAndPhotoGet,
  TitleAndOverviewGet,
} from "../actions/freelancerProfile";
import { postJobGet } from "../actions/postJob";

import { lazy , Suspense} from "react";
import store from "../store";
import setAuthToken from "../utils/setAuthToken";
import MyRoute from "./MyRoute";
import startContract from "../pages/startContract";
import publicProfile from "../pages/profile/publicProfile";
import { HashRouter } from "react-router-dom/cjs/react-router-dom.min";
import ScrollToTop from "./scrollToTop";
import RequestService from "../pages/requestService/RequestService";

/*import {
  Job,
} from "../pages";*/

// Lazily load components
// Actions Files
/*const CertificateGet = lazy(() => import("../actions/freelancerProfile/CertificateGet"));
const EducationGet = lazy(() => import("../actions/freelancerProfile/EducationGet"));
const EmploymentGet = lazy(() => import("../actions/freelancerProfile/EmploymentGet"));
const ExpertiseGet = lazy(() => import("../actions/freelancerProfile/expertiseGet"));
const ExpertLevelGet = lazy(() => import("../actions/freelancerProfile/expertLevelGet"));
const HourlyRateGet = lazy(() => import("../actions/freelancerProfile/HourlyRateGet"));
const LanguageGet = lazy(() => import("../actions/freelancerProfile/LanguageGet"));
const LocationGet = lazy(() => import("../actions/freelancerProfile/locationGet"));
const ProfileAndPhotoGet = lazy(() => import("../actions/freelancerProfile/ProfileAndPhotoGet"));
const TitleAndOverviewGet = lazy(() => import("../actions/freelancerProfile/TitleAndOverviewGet"));
const postJobGet = lazy(() => import("../actions/postJob/postJobGet"));*/



// Pages
const Home = lazy(() => import("../pages/home/Home"));
const ContractDetails = lazy(() => import("../pages/contractDetails/contractDetails"));
const ContractList = lazy(() => import("../pages/contractTrack/index"));

const OtpAuth = lazy(() => import("../pages/login/OtpAuth"));
const Phone = lazy(() => import("../pages/Phone/phone"));

const PostJobClient = lazy(() => import("../pages/postjobclient/postjobclient"));
const Profile = lazy(() => import("../pages/profile/Profile"));
const profileAndPhoto = lazy(() => import("../pages/profileAndPhoto/profileandphoto"));

const RegisterAdmin = lazy(() => import("../pages/register/RegisterAdmin"));
const SecureAccount = lazy(() => import("../pages/login/SecureAccount"));
const Contracts = lazy(() => import("../pages/contracts/contracts"));
const About = lazy(() => import("../pages/about/about"));
const Report = lazy(() => import("../pages/report/report"));
const BankDetails = lazy(() => import("../pages/bankDetails/BankDetails"));
const PayoutApproval = lazy(() => import("../pages/payoutApproval/PayoutApproval"));
const Blank = lazy(() => import("../pages/blank/blank"));
const Refund = lazy(() => import("../pages/refund/refund"));
const LoginRegister = lazy(() => import("../pages/loginRegister/LoginRegister"));
const Enterprise = lazy(() => import("../pages/enterprise/enterprise"));
const RegisterRole = lazy(() => import("../pages/registerRole/RegisterRole"));

// Question Pages
const Question1 = lazy(() => import("../pages/form/question1"));
const Question2 = lazy(() => import("../pages/form/question2"));
const Question3 = lazy(() => import("../pages/form/question3"));
const Question4 = lazy(() => import("../pages/form/question4"));
const Question5 = lazy(() => import("../pages/form/question5"));
const Question6 = lazy(() => import("../pages/form/question6"));
const Question7 = lazy(() => import("../pages/form/question7"));
const Question8 = lazy(() => import("../pages/form/question8"));
const Question9 = lazy(() => import("../pages/form/question9"));
const Question10 = lazy(() => import("../pages/form/question10"));

// Other Components
const AdminDashboard = lazy(() => import("../pages/adminDashboard/admindashboard"));
const ApplyForJob = lazy(() => import("../pages/applyforajob/applyforajob"));
const Budget = lazy(() => import("../pages/budget/budget"));
const Certificate = lazy(() => import("../pages/certificate/certificate"));
const ChangePassword = lazy(() => import("../pages/changePassword/ChangePassword"));
const ClientCompleteProfile = lazy(() => import("../pages/clientCompleteProfile/clientCompleteProfile"));
const ClientProfile = lazy(() => import("../pages/clientProfile/clientProfile"));
const CompleteProfile = lazy(() => import("../pages/completeProfile/completeprofile"));
const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
const Education = lazy(() => import("../pages/education/education"));
const Employment = lazy(() => import("../pages/employment/employment"));
const PersonalDetails = lazy(() => import("../pages/personaldetails/personaldetails"));
const Expertise = lazy(() => import("../pages/expertise/expertise"));
const ExpertiseLevel = lazy(() => import("../pages/ExpertiseLevel/expertiselevel"));
const Finance = lazy(() => import("../pages/finance/finance"));
const ForgetPassword = lazy(() => import("../pages/forgetPassword/forgetPassword"));
const Headline = lazy(() => import("../pages/headline/headline"));
const HourlyRate = lazy(() => import("../pages/hourlyRate/hourlyrate"));
const Job = lazy(() => import("../pages/job/job"));
const Languages = lazy(() => import("../pages/languages/languages"));
//const Location = lazy(() => import("../pages/Location/location"));
const Login = lazy(() => import("../pages/login/Login"));
const Messages = lazy(() => import("../pages/messages/messages"));
const Qa = lazy(() => import("../pages/qa/qa"));
const Register = lazy(() => import("../pages/register/Register"));
const Scope = lazy(() => import("../pages/scope/scope"));

const Settings = lazy(() => import("../pages/settings/settings"));

const Skills = lazy(() => import("../pages/skills/skills"));
const Talent = lazy(() => import("../pages/talent/talent"));
const TitleAndOverview = lazy(() => import("../pages/titleAndOverview/titleandoverview"));
const coc = lazy(() => import("../pages/codeOfConduct/coc"));

const feeAndCharges = lazy(() => import("../pages/feeAndCharges/feeAndCharges"));

const privacyPolicy = lazy(() => import("../pages/privacyPolicy/privacyPolicy"));

const copyright = lazy(() => import("../pages/copyright/copyright"));

const Wallet = lazy(() => import("../pages/wallet/wallet"));
const ExpertVerify = lazy(() => import("../pages/expertverify/expertVerify"));

//const Stripe = lazy(() => import("../pages/Stripe"));
//const PaymentForm = lazy(() => import("../pages/PaymentForm"));

const Verification = lazy(() => import("../pages/verification/verification"));
const PayPalButton = lazy(() => import("../pages/paypal/paypal"));
const NotFound = lazy(() => import("../pages/notFound/NotFound"));
const ManageUsers = lazy(() => import("../pages/manageUsers/ManageUsers"));
const Dispute = lazy(() => import("../pages/dispute/dispute"));




const AppRoutes = (props) => {
  const [authenticated, setAuthenticated] = React.useState(false);

  useEffect(() => {
    setAuthenticated(store.getState().auth.isAuthenticated);
  }, [store.getState().auth]);

  useEffect(() => {
    if (store.getState().auth.token) {
      store.dispatch(loadUser());
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      store.dispatch(loadUser());
      store.dispatch(expertiseGet());
      store.dispatch(expertLevelGet());
      store.dispatch(EducationGet());
      store.dispatch(EmploymentGet());
      store.dispatch(LanguageGet());
      store.dispatch(HourlyRateGet());
      store.dispatch(ProfileAndPhotoGet());
      store.dispatch(PhoneNumberGet());
      store.dispatch(locationGet());
      store.dispatch(TitleAndOverviewGet());
      store.dispatch(CertificateGet());
      store.dispatch(hireManagerGet());
      store.dispatch(postJobGet());
    }
  }, [authenticated]);

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
  }, []);

  return (
    <ScrollToTop>
      <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <MyRoute exact path="/" component={Home} />
        <MyRoute exact path="/about" component={About} />
        <MyRoute exact path="/contractDetails" component={ContractDetails} />
        <MyRoute exact path="/report" component={Report} />
        <MyRoute exact path="/contracts" component={Contracts} />
        <MyRoute exact path="/login" component={Login} />
        <MyRoute exact path="/optAuth" component={OtpAuth} />
        <MyRoute exact path="/secureaccount" component={SecureAccount} />
        <MyRoute exact path="/register" component={Register} />
        <MyRoute exact path="/login-register" component={LoginRegister} />
        <MyRoute exact path="/register-role" component={RegisterRole} />
        <MyRoute exact path="/request-service" component={RequestService} />
        <MyRoute exact path="/registeradmin" component={RegisterAdmin} />
        <MyRoute exact path="/ForgetPassword" component={ForgetPassword} />
        <MyRoute exact path="/completeprofile" component={CompleteProfile} />
        <MyRoute
          exact
          path="/clientcompleteprofile"
          component={ClientCompleteProfile}
        />
        <MyRoute exact path="/messages" component={Messages} />
        <MyRoute exact path="/job" component={Job} />
        <MyRoute exact path="/applyforjob" component={ApplyForJob} />
        <MyRoute exact path="/talent" component={Talent} />
        <MyRoute exact path="/personaldetails" component={PersonalDetails} />
        <MyRoute exact path="/expertise" component={Expertise} />
        <MyRoute exact path="/expertiselevel" component={ExpertiseLevel} />
        <MyRoute exact path="/education" component={Education} />
        <MyRoute exact path="/certificate" component={Certificate} />
        <MyRoute exact path="/employment" component={Employment} />
        <MyRoute exact path="/languages" component={Languages} />
        <MyRoute exact path="/hourlyrate" component={HourlyRate} />
        <MyRoute exact path="/titleandoverview" component={TitleAndOverview} />
        <MyRoute exact path="/profileandphoto" component={profileAndPhoto} />
        {/* <MyRoute exact path="/location" component={Location} /> */}
        <MyRoute exact path="/phone" component={Phone} />
        <MyRoute exact path="/dashboard" component={Dashboard} />
        <MyRoute exact path="/admindashboard" component={AdminDashboard} />
        <MyRoute exact path="/profile" component={Profile} />
        <MyRoute exact path="/profile/:id" component={publicProfile} />
        <MyRoute exact path="/clientprofile" component={ClientProfile} />
        <MyRoute exact path="/settings" component={Settings} />
        <MyRoute exact path="/finance" component={Finance} />
        <MyRoute exact path="/contactus" component={Qa} />
        <MyRoute exact path="/coc" component={coc} />
        <MyRoute exact path="/feeAndCharges" component={feeAndCharges} />
        <MyRoute exact path="/privacyPolicy" component={privacyPolicy} />
        <MyRoute exact path="/copyright" component={copyright} />
        <MyRoute exact path="/postjobclient" component={PostJobClient} />
        <MyRoute exact path="/contract" component={ContractList} />
        <MyRoute exact path="/budget" component={Budget} />
        <MyRoute exact path="/headline" component={Headline} />
        <MyRoute exact path="/startcontract" component={startContract} />
        <MyRoute exact path="/skills" component={Skills} />
        <MyRoute exact path="/scope" component={Scope} />
        <MyRoute exact path="/login-register" component={LoginRegister} />
        <MyRoute exact path="/wallet" component={Wallet} />
        <MyRoute exact path="/dispute" component={Dispute} />
        <MyRoute exact path="/verification" component={Verification} />
        <MyRoute exact path="/paypal" component={PayPalButton} />
        <MyRoute exact path="/bank-details" component={BankDetails} />
        <MyRoute exact path="/payout-approval" component={PayoutApproval} />
        <MyRoute exact path="/manage-users" component={ManageUsers} />
        <MyRoute exact path="/expert-email-verify" component={ExpertVerify} />
        <MyRoute exact path="/blank" component={Blank} />
        <MyRoute exact path="/refund" component={Refund} />
        <MyRoute exact path="/enterprise" component={Enterprise} />
        <MyRoute exact path="/question1/:formType" component={Question1} />
        <MyRoute exact path="/question2/:formType" component={Question2} />
        <MyRoute exact path="/question3/:formType" component={Question3} />
        <MyRoute exact path="/question4/:formType" component={Question4} />
        <MyRoute exact path="/question5/:formType" component={Question5} />
        <MyRoute exact path="/question6/:formType" component={Question6} />
        <MyRoute exact path="/question7/:formType" component={Question7} />
        <MyRoute exact path="/question8/:formType" component={Question8} />
        <MyRoute exact path="/question9/:formType" component={Question9} />
        <MyRoute exact path="/question10/:formType" component={Question10} />
        <MyRoute exact path="/*" component={NotFound} />
      </Switch>
      </Suspense>
      </ScrollToTop>
   
  );
};

export default AppRoutes;
