import { AI_EXPERT_RECOMENDATIONS , GET_INDIVIDUAL , GET_COMPANY, CLEAR_RECOMMENDATIONS , GET_CHAT} from "../actions/types";

const initialState = {
    companies: [],
    individuals: [],
    recomendations: [],
    Chat: []
};

export default function Bank(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_INDIVIDUAL:   
            return {
                ...state,
                individuals: action.payload,
                loading: false
            };
        case GET_COMPANY:
            return {
                ...state,
                companies: action.payload,
                loading: false
            };
            
        case AI_EXPERT_RECOMENDATIONS:
            return {
                ...state,
                recomendations: action.payload,
                loading: false
            };
        case CLEAR_RECOMMENDATIONS:
            return {
                ...state,
                recomendations: [],
                loading: false
            };
        case GET_CHAT:
            return {
                ...state,
                Chat: action.payload,
                loading: false
            };    
        default:
            return state;
    }
}
