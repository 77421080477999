import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import adminDashboard from './adminDashboard';
import freelancerProfile from './freelancerProfile';
import clientProfile from './clientProfile';
import postJob from './postJob';
import proposalAndContract from './proposalAndContract';
import sendEmail from './sendEmail';
import wallet from './wallet';
import transaction from './transaction';
import bank from './bank';
import withdrawal from './withdrawal';
import adminGetAllUser from './manageUsers';
import dispute from './dispute';
import enterprise from './enterprise';
import taps from './checkout';

export default combineReducers({
    adminDashboard,
    alert,
    auth,
    freelancerProfile,
    clientProfile,
    postJob,
    proposalAndContract,
    sendEmail,
    wallet,
    transaction,
    bank,
    withdrawal,
    adminGetAllUser,
    dispute,
    taps,
    enterprise
});