import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';

//axios.defaults.baseURL = 'https://hidden-cliffs-88299.herokuapp.com';
//axios.defaults.baseURL = 'http://localhost:5000';
//axios.defaults.baseUrl = 'http://ec2-54-174-65-108.compute-1.amazonaws.com:5000';
//axios.defaults.baseURL = 'http://cyber2cyber-env.eba-edf8uert.us-east-1.elasticbeanstalk.com'
axios.defaults.baseURL = 'https://cybertocyber.com'; 
//axios.defaults.baseURL = 'https://staging.cybertocyber.com'; 

axios.interceptors.request.use(request => {
    // Edit request config
    return request;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error);
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
