import { PENDING_JOBS_GET_SUCCESS } from '../actions/types';

const initialState = {
    pendingJobs:[],
    pendingJobs2:[],
    
};


export default function adminDashboard(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
        case PENDING_JOBS_GET_SUCCESS:
            //console.log("paycheck",payload[0])
            if (Array.isArray(payload) && payload.length > 0) {
                return {
                    ...state , loading: true, pendingJobs2: payload
                };
            } else {
                console.error("Payload is not an array or is empty");
                return state;
            }
          
           
		default:
			return state;
	}
}
