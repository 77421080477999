import { GET_BANKS, GET_BANK } from "../actions/types";

const initialState = {
  banks: null,
  bank: null,
};

export default function Bank(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BANKS:
      return {
        ...state,
        banks: payload,
      };
    case GET_BANK:
      return {
        ...state,
        bank: payload,
      };
    default:
      return state;
  }
}
