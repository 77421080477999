import {
    DISPUTES_GET_SUCCESS,
    DISPUTES_GET_FAIL,
} from '../actions/types';

const initialState = {
    disputes: [],
    loading: true,
    error: null
};

export default function dispute(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case DISPUTES_GET_SUCCESS:   
            return {
                ...state,
                disputes: action.payload,
                loading: false
            };
        case DISPUTES_GET_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
      
        default:
            return state;
    }
}
