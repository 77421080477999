import {
	EXPERTISE_GET,
	EXPERTLEVEL_GET,
	EDUCATION_GET,
	EMPLOYMENT_GET,
	LANGUAGE_GET,
	HOURLYRATE_GET,
	PROFILEANDPHOTO_GET,
	FREELANCERLOCATION_GET,
	FREELANCERTITLEOVERVIEW_GET,
	CERTIFICATE_GET,
	FREELANCER_STATUS_GET,
	FREELANCER_IS_COMPLETED_GET,
	FREELANCER_LIST_GET,
	FREELANCER_GET,
	REVIEWS_GET,
	PROFILE_COMPLETE_PERCENTAGE
} from '../actions/types';

const initialState = {
	freelancerStatus: false,
	freelancerList: null,
	isLoaded: false,
	freelancerprofile: {
		"empty": true
	},
	expertise: {
		category: '',
		skills: ''
	},
	expertLevel: {
		expert_level: ''
	},
	education: {
		provider:'',
		from: '',
		to: '',
		current: '',
		degree: '',
		specialization: '',
		description: ''
	},
	employment: {
		company_name: '',
		city: '',
		country: '',
		title: '',
		from: '',
		to: '',
		current: '',
		description: ''
	},
	languageVal: {
		language: '',
		proficiency: ''
	},
	hourlyRate: {
		hourly_rate: ''
	},
	profileAndPhoto: {
		photo_link: ''
	},
	phoneNumber: {
		phone: '',
		verified: ''
	},
	Location: {
		country: '',
		address: '',
		suite: '',
		city: '',
		province: '',
		zip: ''
	},
	TitleAndOverview: {
		title: '',
		professional_overview: ''
	},
	certification: {
        certification_name: '',
        provider: '',
        description: '',
	},
	reviews: [],
	isProfileCompleted: false,
	profileCompletedPercentage: 0,
	profileCompletedItems: {},
	isfreelancerActionable: false,
}

const getExpertProfilePercentage = (state)=>{
    let ProfileCompletionPercentage = 10;
    let CompletedItems = {}
	let freelancerActionable = false;
    if(state?.expertise != null && (state?.expertise?.length > 0 || state?.expertise?.skills)){
      ProfileCompletionPercentage += 10;
	  CompletedItems.expertise = true;
    }
    if(state?.expertLevel != null && (state?.expertLevel?.length > 0 || state?.expertLevel?.expert_level)){
      ProfileCompletionPercentage += 10;
	  CompletedItems.expertLevel = true;
    }
    if(state?.education != null && state?.education?.length > 0 ){
      ProfileCompletionPercentage += 5;
	  CompletedItems.education = true;
    }
    if(state?.certification != null && state?.certification?.length > 0){
      ProfileCompletionPercentage += 5;
	  CompletedItems.certification = true;
    }
    if(state?.employment != null && state?.employment?.length > 0){
      ProfileCompletionPercentage += 10;
	  CompletedItems.employment = true;
    }
    if(state?.languageVal != null && state?.languageVal?.length > 0){
      ProfileCompletionPercentage += 10;
	  CompletedItems.languageVal = true;
    }
    if(state?.hourlyRate != null && (state?.hourlyRate?.length > 0 || state?.hourlyRate?.hourly_rate)){
      ProfileCompletionPercentage += 10;
	  CompletedItems.hourlyRate = true;
    }
    if(state?.TitleAndOverview != null && (state?.TitleAndOverview?.length > 0 || state?.TitleAndOverview?.title)){
      ProfileCompletionPercentage += 10;
	  CompletedItems.TitleAndOverview = true;
    }
    if(state?.profileAndPhoto != null && state?.profileAndPhoto?.photo_link){
      ProfileCompletionPercentage += 10;
	  CompletedItems.profileAndPhoto = true;
    }
	if(state?.freelancerprofile){
		ProfileCompletionPercentage += 10;
	}
	if(CompletedItems?.expertise && CompletedItems?.expertLevel && CompletedItems?.languageVal && CompletedItems?.TitleAndOverview && CompletedItems?.profileAndPhoto){
		freelancerActionable = true;
	}

    return {ProfileCompletionPercentage, CompletedItems, freelancerActionable};
}

export default function freelancerProfile(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {

		case FREELANCER_GET:
			return {
				...state, isAuthenticated: true, loading: false, freelancerprofile: payload
			}
		case FREELANCER_LIST_GET:
			return {
				...state, isAuthenticated: true, loading: false, isLoaded: true, freelancerList: payload
			}
		case FREELANCER_STATUS_GET:
			return {
				...state, isAuthenticated: true, loading: false, freelancerStatus: payload
			}
		case FREELANCER_IS_COMPLETED_GET:
			return {
				...state, isAuthenticated: true, loading: false, isProfileCompleted: payload
			}
		case EXPERTISE_GET:
			return {
				...state, isAuthenticated: true, loading: false, expertise: payload
			}
		case EXPERTLEVEL_GET:
			return {
				...state, isAuthenticated: true, loading: false, expertLevel: payload
			}
		case EDUCATION_GET:
			return {
				...state, isAuthenticated: true, loading: false, education: payload
			}
		case CERTIFICATE_GET:
			return {
				...state, isAuthenticated: true, loading: false, certification: payload
			}
		case EMPLOYMENT_GET:
			return {
				...state, isAuthenticated: true, loading: false, employment: payload
			}
		case LANGUAGE_GET:
			return {
				...state, isAuthenticated: true, loading: false, languageVal: payload
			}
		case HOURLYRATE_GET:
			return {
				...state, isAuthenticated: true, loading: false, hourlyRate: payload
			}
		case PROFILEANDPHOTO_GET:
			return {
				...state, isAuthenticated: true, loading: false, profileAndPhoto: payload
			}
		case FREELANCERLOCATION_GET:
			return {
				...state, isAuthenticated: true, loading: false, Location: payload
			}
		case FREELANCERTITLEOVERVIEW_GET:
			return {
				...state, isAuthenticated: true, loading: false, TitleAndOverview: payload
			}
		case REVIEWS_GET:
			return {
				...state, isAuthenticated: true, loading: false, reviews: payload
			}
		case PROFILE_COMPLETE_PERCENTAGE:
			const {ProfileCompletionPercentage, CompletedItems, freelancerActionable} = getExpertProfilePercentage(state);
			return{
				...state, isAuthenticated: true, loading: false, profileCompletedPercentage: ProfileCompletionPercentage, profileCompletedItems: CompletedItems, isfreelancerActionable: freelancerActionable,
			}

		default:
			return state;
	}
}