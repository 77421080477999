import { GETTRANSACTION, GET_TRANSACTION_HISTORY } from '../actions/types';
import { SETTRANSACTION } from '../actions/types';

const initialState = {
	transactionHistory: null
};

export default function transaction(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
        case GETTRANSACTION:
            return {
				...state,
				transactions: payload.transactions
			};
		case GET_TRANSACTION_HISTORY:
			return {
				...state,
				transactionHistory: payload.transactions
			};
		default:
			return state;
	}
}