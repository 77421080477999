import { INSERTWALLET } from '../actions/types';

const initialState = {
    walletAmount: null
};


export default function wallet(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
        case INSERTWALLET:
            return {
                ...state,
                walletAmount: payload
            }
		default:
			return state;
	}
}